import axios from "axios";
import React, { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import TanStackTable from "../../common/Table/TanStackTable";
import SimpleBackdrop from "../../common/Spinner/Spinner";
import { useSelector } from "react-redux";
import InvoiceModal from "./InvoiceModal";
import { useDispatch } from "react-redux";
import Select from "react-select";
import ConsignerFilter from "../Filters/ConsignerFilter";
import ConsigneeFilter from "../Filters/ConsigneeFilter";
import InvoiceNumberFilter from "../Filters/InvoiceNumberFilter";
import SourceFilter from "../Filters/SourceFilter";
import DestinationFilter from "../Filters/DestinationFilter";
import DateFilter from "../Filters/DateFilter";
import { BASE_URL } from "../../../config";
import { setLogout } from "../../../features/Login";


const ActiveTrips = () => {

  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const selectedConsigner = useSelector(
    (state) => state?.filterComponents?.selectedConsigner
  );
  const selectedConsignee = useSelector(
    (state) => state?.filterComponents?.selectedConsignee
  );
  const selectedInvoiceNumber = useSelector(
    (state) => state?.filterComponents?.selectedInvoiceNumber
  );
  const selectedSource = useSelector(
    (state) => state?.filterComponents?.selectedSource
  );
  const selectedDestination = useSelector(
    (state) => state?.filterComponents?.selectedDestination
  );
  const selectedDate = useSelector(
    (state) => state?.filterComponents?.selectedDate
  );
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [selectedProduct, setSelectedProduct] = useState();
  const [truckNumber, setTruckNumber] = useState();
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(true);
  const [truck, setTruck] = useState();
  const [buttonType, setButtonType] = useState();
  const [modalHandler, setModelHandler] = useState();
  const [tripId, setTripId] = useState();
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "application/json",
  };
  const updateType = "update_trip";

  const columnHelper = createColumnHelper();
  const dispatch = useDispatch();

  useEffect(() => {
    const query = {
      query: `query($firm_id:ID!,
                            $trip_status:String,
                            $truck_number:String,
                            $trip_date:String,
                            $trip_consigner:String,
                            $trip_consignee:String,
                            $trip_source:String,
                            $trip_destination:String,
                            $trip_product:String,
                            $trip_invoice_number:String
                            ){
                  firmTrip(firm_id:$firm_id,
                           truck_number:$truck_number,
                           trip_status:$trip_status,
                           trip_date:$trip_date,
                           trip_consigner: $trip_consigner,
                           trip_consignee:$trip_consignee,
                           trip_source:$trip_source,
                           trip_destination:$trip_destination,
                           trip_product:$trip_product,
                           trip_invoice_number:$trip_invoice_number
                           ){
                    truckTrip {
                      truck_id
                      trip_id
                      trip_date
                      trip_source
                      trip_destination
                      trip_status
                      trip_consigner
                      trip_consignee
                      trip_product
                      trip_invoice_number
                      trip_invoice_value 
                      truck{
                        truck_number
                      }
                    }
                }
              }`,
      variables: {
        firm_id: firm_id,
        truck_number: truck,
        trip_status: "Active",
        trip_date: selectedDate,
        trip_source: selectedSource,
        trip_destination: selectedDestination,
        trip_consigner: selectedConsigner,
        trip_consignee: selectedConsignee,
        trip_product: selectedProduct,
        trip_invoice_number: selectedInvoiceNumber,
      },
    };
    axios
      .post(BASE_URL, query, { headers })
      .then((response) => {
        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout());
        } else {
          setContent(response.data?.data.firmTrip.truckTrip);
          setContent(content => [...content].reverse())
          setTruckNumber(
            response.data?.data.firmTrip.truckTrip?.map((trip) => {
              return {
                value: trip.truck.truck_number,
                label: trip.truck.truck_number,
              };
            })
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [
    firm_id,
    truck,
    selectedDate,
    selectedSource,
    selectedDestination,
    selectedConsigner,
    selectedConsignee,
    selectedProduct,
    selectedInvoiceNumber,
    modalHandler
  ]);

  const columns = [
    columnHelper.accessor((row) => row.truck.truck_number, {
      id: "truck_number",
      cell: (info) => info.getValue(),
      header: () => <span>Truck Number</span>,
    }),
    columnHelper.accessor("trip_date", {
      header: "Date",
      cell: (info) => info.getValue().split("-").reverse().join("-"),
    }),

    columnHelper.accessor("trip_source", {
      header: "Source",
    }),
    columnHelper.accessor("trip_destination", {
      header: "Destination",
    }),
    columnHelper.accessor("trip_consigner", {
      header: "Consigner",
    }),
    columnHelper.accessor("trip_consignee", {
      header: "Consignee",
    }),
    columnHelper.accessor("trip_product", {
      header: "Product",
    }),
    columnHelper.accessor("trip_invoice_number", {
      header: "Invoice Number",
    }),
    columnHelper.accessor("trip_invoice_value", {
      header: "Invoice Value",
      cell: (info) => "₹ " + info.getValue(),
    }),
    columnHelper.accessor("trip_id", {
      header: "Update",
      cell: (props) => (
        <button
          type="button"
          value={props.getValue()}
          className="dark-btn rounded m-auto "
          onClick={(e) => handleModalShow(e, "close")}
        >
          close
        </button>
      ),
    }),
    columnHelper.accessor("trip_id", {
      header: "Builty",
      cell: (props) => (
        <button
          type="button"
          value={props.getValue()}
          className="dark-btn rounded m-auto  bi bi-eye"
          onClick={(e) => handleModalShow(e, "builty")}
        ></button>
      ),
    }),
  ];
  const handleModalShow = (e, item) => {
    setModelHandler(true);
    setTripId(e?.target.value);
    setButtonType(item)
  }

  function ModalAction(item) {
    setModelHandler(item)
  }
  const handleSelect = (event) => {
    setTruck(event?.value);
  };

  return (
    <>
      <div className="d-flex justify-content-evenly mb-2 ">
        <div className="d-flex flex-column w-25 pe-2 mt-3">
          <label>Date</label>
          <DateFilter />
        </div>
        <div className="d-flex flex-column w-25 pe-2 mt-3">
          <label>Truck Number</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            onChange={handleSelect}
            options={truckNumber}
          />
        </div>
        <div className="d-flex flex-column w-25 mt-3">
          <label>Invoice Number</label>
          <InvoiceNumberFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Consigner</label>
          <ConsignerFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Consignee</label>
          <ConsigneeFilter />
        </div>
        <div className="d-flex flex-column w-25 mt-3">
          <label>Source</label>
          <SourceFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Destination</label>
          <DestinationFilter />
        </div>

      </div>
      <hr />
      {loading
        ?
        (<SimpleBackdrop />)
        : content?.length > 0 ? (
          <>
            <TanStackTable data={content} columns={columns} />
          </>
        ) : (
          <div
            className=" alert alert-info d-flex justify-content-center"
            role="alert"
          >
            No Data to Show
          </div>
        )}
      {modalHandler && (
        <InvoiceModal
          tripId={tripId}
          modalHandler={modalHandler}
          updateType={updateType}
          modalAction={ModalAction}
          buttonType={buttonType}
        />
      )}
    </>
  );
};

export default ActiveTrips;

import { createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import TanStackTable from "../../common/Table/TanStackTable";
import "../../common/Form/Form.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBackdrop from "../../common/Spinner/Spinner";
import Select from "react-select";
import { City } from "country-state-city";
import { BASE_URL } from "../../../config";
import { setLogout } from "../../../features/Login";
import DateFilter from "../Filters/DateFilter";

const ExpenseData = () => {
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const selectedDate = useSelector((state) => state.filterComponents.selectedDate);
  const columnHelper = createColumnHelper();
  const dispatch = useDispatch();
  const [content, setContent] = useState();
  const [selectedSource, setSelectedSource] = useState();
  const [selectedDestination, setSelectedDestination] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [truck, setTruck] = useState("");
  const [loading, setLoading] = useState(true);
  const [expenseType, setExpenseType] = useState("trip_expense");
  const [truckLoading, setTruckLoading] = useState(true);
  const [TruckNumberData, setTruckNumberData] = useState([]);
  const cities = City.getCitiesOfCountry("IN");
  const CitiesOption = cities?.map((c) => {
    return { value: c.name, label: c.name };
  });
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "application/json",
  };
  const tripStatus = [
    { value: "Active", label: "Active" },
    { value: "closed", label: "Closed" },
  ];
  const expenseTypeData = [
    { value: "trip_expense", label: "Trip Expense" },
    { value: "truck_expense", label: "Truck Expense" },
  ];

  useEffect(() => {
    //  =====  Query to get Truck Numbers  ======
    const truckQuery = {
      query: `query($firm_id:ID!){
                truck(firm_id:$firm_id){
                  truck_number
                }
              }`,
      variables: {
        firm_id: firm_id,
      },
    };
    axios
      .post(BASE_URL, truckQuery, { headers })
      .then((response) => {
        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout());
        } else {
          setTruckLoading(false);
          setTruckNumberData(
            response.data?.data.truck.map((d) => {
              return { value: d.truck_number, label: d.truck_number };
            })
          );
        }
      })
      .catch((error) => {
        setTruckLoading(false);
      });
  }, [firm_id]);

  useEffect(() => {
    //   ==== Query to get Truck Expenses  ======
    const expenseQuery = {
      query: `query($firm_id:ID!,$truck_number:String!){
                truck(firm_id:$firm_id,truck_number:$truck_number) {
                  expense {
                    expense_date
                    expense_type
                    expense_area
                    expense_amount
                  }
                }
              }`,
      variables: {
        firm_id: firm_id,
        truck_number: truck,
      },
    };

    // ===== Query to get Trip Expenses =====

    const tripExpenseQuery = {
      query: `query($firm_id:ID!,
                          $truck_number:String,
                          $trip_source:String,
                          $trip_destination:String
                          $trip_status:String
                          $trip_expense_date:Date){
                truckTripExpense(truck_number:$truck_number,
                                 firm_id: $firm_id,
                                 trip_source:$trip_source,
                                 trip_destination:$trip_destination
                                 trip_status:$trip_status
                                 trip_expense_date:$trip_expense_date) {
                 
                    trip_id
                    trip_expense_id
                    trip_expense_date
                    trip_expense_amount
                    trip_expense_area
                  
                }
              }`,
      variables: {
        firm_id: firm_id,
        truck_number: truck,
        trip_source: selectedSource,
        trip_destination: selectedDestination,
        trip_status: selectedStatus,
        trip_expense_date: selectedDate
      },
    };

    {
      expenseType == "truck_expense"
        ? axios
          .post(BASE_URL, expenseQuery, { headers })
          .then((response) => {
            setContent(response.data?.data.truck[0].expense);
            setContent(content => [...content].reverse())
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          })
        : axios
          .post(BASE_URL, tripExpenseQuery, { headers })
          .then((response) => {
            if (response.data?.errors?.[0].message == "Unauthenticated.") {
              dispatch(setLogout());
            } else {
              setContent(response.data.data.truckTripExpense);
              setContent(content => [...content].reverse())
              setLoading(false);
            }
          })
          .catch((error) => { });
    }
  }, [
    selectedDate,
    truck,
    firm_id,
    expenseType,
    selectedSource,
    selectedDestination,
    selectedStatus
  ]);

  const handleSourceSelect = (e) => {
    setSelectedSource(e?.value);
  };
  const handleDestinationSelect = (e) => {
    setSelectedDestination(e?.value);
  };
  const handleTripStatusSelect = (e) => {
    setSelectedStatus(e?.value);
  };

  const columns1 = [
    columnHelper.accessor("expense_date", {
      header: "Date",
      cell: (info) => info.getValue().split("-").reverse().join("-"),
    }),
    columnHelper.accessor("expense_type", {
      header: "Expense Type",
    }),
    columnHelper.accessor("expense_area", {
      header: "Expense Area",
    }),
    columnHelper.accessor("expense_amount", {
      header: "Expense Amount",
      cell: (info) => "₹ " + info.getValue(),
    }),
  ];

  const columns2 = [
    columnHelper.accessor("trip_expense_date", {
      header: "Date",
      cell: (info) => info.getValue().split("-").reverse().join("-"),
    }),
    columnHelper.accessor("trip_expense_area", {
      header: "Trip Expense Area",
    }),
    columnHelper.accessor("trip_expense_amount", {
      header: "Trip Expense Amount",
      cell: (info) => "₹ " + info.getValue(),
    }),
  ];

  const handleSelectTruck = (e) => {
    setTruck(e?.value);
  };
  const handleExpenseType = (e) => {
    setContent();
    setExpenseType(e?.value);
  };

  return (
    <>
      <div className="d-flex ">
        <div className="d-flex flex-column w-25 mt-2">
          <label>Expense Type</label>
          <Select
            className="basic-single w-100 pe-lg-2 pe-md-2"
            classNamePrefix="select"
            defaultValue={expenseTypeData[0]}
            // onChange={(e) => setExpenseType(e?.value)}
            onChange={handleExpenseType}
            options={expenseTypeData}
          />
        </div>

        {expenseType == "trip_expense"
          &&
          <div className="d-flex flex-column w-25 mt-2 me-2">
            <label>Trip Date</label>
            <DateFilter />
          </div>
        }
        <div className="d-flex flex-column w-25 mt-2">
          <label>Truck Number</label>
          <Select
            className="basic-single w-100 pe-lg-2 pe-md-2"
            classNamePrefix="select"
            isClearable
            defaultValue={TruckNumberData[0]}
            onChange={handleSelectTruck}
            options={TruckNumberData}
          />
        </div>

        {expenseType == "trip_expense" && (
          <div className="d-flex flex-column w-25 mt-2">
            <label>Status</label>
            <Select
              className="basic-single w-100 pe-lg-2 pe-md-2"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              onChange={handleTripStatusSelect}
              options={tripStatus}
            />
          </div>
        )}

        {expenseType == "trip_expense" && (
          <div className="d-flex flex-column w-25 mt-2">
            <label>Source</label>
            <Select
              className="basic-single w-100 pe-lg-2 pe-md-2"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              onChange={handleSourceSelect}
              options={CitiesOption}
            />
          </div>
        )}

        {expenseType == "trip_expense" && (
          <div className="d-flex flex-column w-25 mt-2">
            <label>Destination</label>
            <Select
              className="basic-single w-100 pe-lg-2 pe-md-2"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              onChange={handleDestinationSelect}
              options={CitiesOption}
            />
          </div>
        )}

      </div>

      <br />

      {loading ? (
        <SimpleBackdrop />
      ) : content?.length ? (
        expenseType == "truck_expense" ? (
          <TanStackTable data={content} columns={columns1} />
        ) : (
          <TanStackTable data={content} columns={columns2} />
        )
      ) : (
        <div
          className=" alert alert-info d-flex justify-content-center text-center"
          role="alert"
        >
          No Data to Show
        </div>
      )}
    </>
  );
};
export default ExpenseData;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setDecrem, setSubmit } from "../../../../features/TruckForm";
import { BASE_URL } from "../../../../config";
import axios from "axios";
import CustomizedSnackbars from "../../../common/Popup/Snackbar";
import Loader from "../../../common/Spinner/Loader";

const TruckDocuments = () => {
  const dispatch = useDispatch();
  const TruckDetails = useSelector(
    (state) => state?.truckFormReducer?.addTruckDetails
  );
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [show, setShow] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [truckError, setTruckError] = useState([]);
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const handleBackStep = (event) => {
    event.preventDefault();
    dispatch(setDecrem());
  };

  const onSubmit = (data) => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "multipart/form-data",
    };

    const rc_file = data.truck_rc[0];
    const puc_file = data.truck_puc[0];
    const insurance_file = data.truck_insurance[0];
    const permit_file = data.truck_permit[0];
    const fitness_file = data.truck_fitness[0];

    const formData = new FormData();

    formData.append(
      "operations",
      JSON.stringify({
        query: `mutation(       $firm_id:ID!,
                                $truck_number:String!,
                                $truck_length:Int!,
                                $trolley_length:Int!,
                                $fastag_number:String!,
                                $old_registration:String,
                                $new_registration:String!,
                                $invoice:Date!,
                                $ownership:String!,
                                $model:String!,
                                $model_type:String!,
                                $manufacturing_year:String!,
                                $engine_number:String!,
                                $total_cost:String!,
                                $body_type:String!,
                                $valid_upto:Date!,
                                $gross_weight:Int!,
                                $unloading_weight:Int!,
                                $payloading_weight:Int!,
                                $chasis_number:String!,
                                $registration_date:Date!,
                                $rto:String!,
                                $purchased_date:Date!,

                                $truck_rc:Upload!,
                                $truck_puc:Upload!,
                                $truck_insurance:Upload!,
                                $truck_permit:Upload!,
                                $truck_fitness:Upload!,

                                $truck_rc_number:String!,
                                $truck_puc_number:String!,
                                $truck_insurance_number:String!,
                                $truck_permit_number:String!,
                                $truck_fitness_number:String!,
                                
                                $rc_issue_date:Date!,
                                $puc_issue_date:Date!,
                                $insurance_issue_date:Date!,
                                $permit_issue_date:Date!,
                                $fitness_issue_date:Date!,
                                
                                $rc_expiry_date:Date!,
                                $puc_expiry_date:Date!,
                                $insurance_expiry_date:Date!,
                                $permit_expiry_date:Date!,
                                $fitness_expiry_date:Date!,
                          ){
                            addTruck( 
                              firm_id:$firm_id,
                              truck_number:$truck_number,
                              model:$model,
                              old_registration:$old_registration,
                              new_registration:$new_registration,
                              manufacturing_year:$manufacturing_year,
                              rto:$rto,
                              purchased_date:$ purchased_date,
                              registration_date:$registration_date,
                              model_type:$model_type,
                              invoice:$invoice,
                              truck_length:$truck_length,
                              valid_upto:$valid_upto,
                              ownership:$ownership,
                              unloading_weight:$unloading_weight,
                              payloading_weight:$payloading_weight,
                              fastag_number:$fastag_number,
                              body_type:$body_type,
                              chasis_number:$chasis_number,
                              engine_number:$engine_number,
                              total_cost:$total_cost,
                              trolley_length:$trolley_length,
                              gross_weight:$gross_weight,

                              truck_rc:$truck_rc,
                              truck_puc:$truck_puc,
                              truck_insurance:$truck_insurance,
                              truck_permit:$truck_permit,
                              truck_fitness:$truck_fitness,

                              truck_rc_number:$truck_rc_number,
                              truck_puc_number:$truck_puc_number,
                              truck_insurance_number:$truck_insurance_number,
                              truck_permit_number:$truck_permit_number,
                              truck_fitness_number:$truck_fitness_number,
                              
                              rc_issue_date:$rc_issue_date,
                              puc_issue_date:$puc_issue_date,
                              insurance_issue_date:$insurance_issue_date,
                              permit_issue_date:$permit_issue_date,
                              fitness_issue_date:$fitness_issue_date,
          
                              rc_expiry_date:$rc_expiry_date,
                              puc_expiry_date:$puc_expiry_date,
                              insurance_expiry_date:$insurance_expiry_date,
                              permit_expiry_date:$permit_expiry_date,
                              fitness_expiry_date:$fitness_expiry_date,
                            )
                            {
                              error{
                                    truck_number
                                    model
                                    old_registration
                                    new_registration
                                    manufacturing_year
                                    valid_upto
                                    ownership
                                    registration_date
                                    fastag_number
                                    rto
                                    engine_number
                                    chasis_number
                                    truck_length
                                    trolley_length
                                    gross_weight
                                    unloading_weight
                                    payloading_weight
                                    purchased_date
                                    invoice
                                    total_cost
                                    model_type
                                    body_type
                          }
                              message
                              type
                            }
                          }`,
        variables: {
          firm_id: firm_id,
          truck_number: TruckDetails?.truck_number,
          model: TruckDetails?.model,
          old_registration: TruckDetails?.old_registration,
          new_registration: TruckDetails?.new_registration,
          manufacturing_year: TruckDetails?.manufacturing_year,
          valid_upto: TruckDetails?.valid_upto,
          ownership: TruckDetails?.ownership,
          registration_date: TruckDetails?.registration_date,
          fastag_number: TruckDetails?.fastag_number,
          rto: TruckDetails?.rto,
          engine_number: TruckDetails?.engine_number,
          chasis_number: TruckDetails?.chasis_number,
          truck_length: +TruckDetails?.truck_length,
          trolley_length: +TruckDetails?.trolley_length,
          gross_weight: +TruckDetails?.gross_weight,
          purchased_date: TruckDetails?.purchased_date,
          unloading_weight: +TruckDetails?.unloading_weight,
          payloading_weight: +TruckDetails?.payloading_weight,
          invoice: TruckDetails?.invoice,
          total_cost: TruckDetails?.total_cost,
          model_type: TruckDetails?.model_type,
          body_type: TruckDetails?.body_type,

          truck_rc: rc_file,
          truck_puc: puc_file,
          truck_insurance: insurance_file,
          truck_permit: permit_file,
          truck_fitness: fitness_file,

          truck_rc_number: data?.truck_rc_number,
          truck_puc_number: data?.truck_puc_number,
          truck_insurance_number: data?.truck_insurance_number,
          truck_permit_number: data?.truck_permit_number,
          truck_fitness_number: data?.truck_fitness_number,

          rc_issue_date: data?.rc_issue_date,
          puc_issue_date: data?.puc_issue_date,
          insurance_issue_date: data?.insurance_issue_date,
          permit_issue_date: data?.permit_issue_date,
          fitness_issue_date: data?.fitness_issue_date,

          rc_expiry_date: data?.rc_expiry_date,
          puc_expiry_date: data?.puc_expiry_date,
          insurance_expiry_date: data?.insurance_expiry_date,
          permit_expiry_date: data?.permit_expiry_date,
          fitness_expiry_date: data?.fitness_expiry_date,
        },
      })
    );

    formData.append("0", rc_file, rc_file.name);
    formData.append("1", puc_file, puc_file.name);
    formData.append("2", insurance_file, insurance_file.name);
    formData.append("3", permit_file, permit_file.name);
    formData.append("4", fitness_file, fitness_file.name);

    formData.append(
      "map",
      JSON.stringify({
        0: ["variables.truck_rc"],
        1: ["variables.truck_puc"],
        2: ["variables.truck_insurance"],
        3: ["variables.truck_permit"],
        4: ["variables.truck_fitness"],
      })
    );

    axios
      .post(BASE_URL, formData, { headers })
      .then((response) => {
        if (response.data?.data.addTruck.type == "success") {
          setLoading(false)
          setMessageType(response.data?.data.addTruck.type);
          setShow(true);
          setTruckError(response.data?.data.addTruck.message);
          setTimeout(() => {
            reset()
          }, 3000);
        }
        if (response.data?.data.addTruck.type == "failed") {
          setLoading(false)
          setMessageType(response.data?.data.addTruck.type);
          setShow(true);
          if (response.data.data.addTruck.error.truck_number != null) {
            setTruckError(response.data?.data.addTruck.error.truck_number[0]);
          } else if (response.data.data.addTruck.error.fastag_number != null) {
            setTruckError(response.data?.data.addTruck.error.fastag_number[0]);
          } else if (response.data.data.addTruck.error.engine_number != null) {
            setTruckError(response.data?.data.addTruck.error.engine_number[0]);
          } else if (response.data.data.addTruck.error.chasis_number != null) {
            setTruckError(response.data?.data.addTruck.error.chasis_number[0]);
          }
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
        dispatch(setSubmit());
        setMessageType();
      }, 4000);
    }
  }, [show]);

  return (
    <>
      <form className="addtruck-form mt-3" onSubmit={handleSubmit(onSubmit)}>
        <ul>
          <li>
            <div className="form-group">
              <label for="truck_rc">Registration Card</label>
              <input
                className="form-control"
                type="file"
                {...register("truck_rc", {
                  required: true,
                })}
              />
              {errors?.truck_rc?.type === "required" && (
                <p className="error_p">Kindly Upload Registration Card </p>
              )}
            </div>
            <div className="form-group">
              <label for="truck_rc_number">RC Number</label>
              <input
                className="form-control"
                type="text"
                {...register("truck_rc_number", {
                  required: true,
                })}
              />
              {errors?.truck_rc_number?.type === "required" && (
                <p className="error_p">RC Number is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="rc_issue_date">Issue Date</label>
              <input
                className="form-control"
                type="date"
                {...register("rc_issue_date", {
                  required: true,
                })}
              />
              {errors?.rc_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="rc_expiry_date">Expiry Date</label>
              <input
                className="form-control"
                type="date"
                {...register("rc_expiry_date", {
                  required: true,
                })}
              />
              {errors?.rc_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
            <div style={{ clear: "both" }}></div> <hr />
          </li>
          {/* ================================ P U C ============================================= */}
          <li>
            <div className="form-group">
              <label for="truck_puc">PUC Certificate</label>
              <input
                className="form-control"
                type="file"
                {...register("truck_puc", {
                  required: true,
                })}
              />
              {errors?.truck_puc?.type === "required" && (
                <p className="error_p">Kindly Upload PUC Certificate</p>
              )}
            </div>
            <div className="form-group">
              <label for="truck_puc_number">PUC Number</label>
              <input
                className="form-control"
                type="text"
                {...register("truck_puc_number", {
                  required: true,
                })}
              />
              {errors?.truck_puc_number?.type === "required" && (
                <p className="error_p">PUC Number is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="puc_issue_date">Issue Date</label>
              <input
                className="form-control"
                type="date"
                {...register("puc_issue_date", {
                  required: true,
                })}
              />
              {errors?.puc_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="puc_expiry_date">Expiry Date</label>
              <input
                className="form-control"
                type="date"
                {...register("puc_expiry_date", {
                  required: true,
                })}
              />
              {errors?.puc_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
            <div style={{ clear: "both" }}></div> <hr />
          </li>
          {/* =================I N S U R A N C E ============================================ */}
          <li>
            <div className="form-group">
              <label for="truck_insurance">Insurance Policy</label>
              <input
                className="form-control"
                type="file"
                {...register("truck_insurance", {
                  required: true,
                })}
              />
              {errors?.truck_insurance?.type === "required" && (
                <p className="error_p">Kindly Upload Insurance Policy</p>
              )}
            </div>
            <div className="form-group">
              <label for="truck_insurance_number">Policy Number</label>
              <input
                className="form-control"
                type="text"
                {...register("truck_insurance_number", {
                  required: true,
                })}
              />
              {errors?.truck_insurance_number?.type === "required" && (
                <p className="error_p">Policy Number is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="insurance_issue_date">Issue Date</label>
              <input
                className="form-control"
                type="date"
                {...register("insurance_issue_date", {
                  required: true,
                })}
              />
              {errors?.insurance_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="insurance_expiry_date">Expiry Date</label>
              <input
                className="form-control"
                type="date"
                {...register("insurance_expiry_date", {
                  required: true,
                })}
              />
              {errors?.insurance_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
            <div style={{ clear: "both" }}></div> <hr />
          </li>
          {/* ============================================================================== */}
          <li>
            <div className="form-group">
              <label for="truck_permit">Permit Certificate</label>
              <input
                className="form-control"
                type="file"
                {...register("truck_permit", {
                  required: true,
                })}
              />
              {errors?.truck_permit?.type === "required" && (
                <p className="error_p">Kindly Upload Permit Certificate</p>
              )}
            </div>

            <div className="form-group">
              <label for="truck_permit_number">Permit Number</label>
              <input
                className="form-control"
                type="text"
                {...register("truck_permit_number", {
                  required: true,
                })}
              />
              {errors?.truck_permit_number?.type === "required" && (
                <p className="error_p">Permit Number is required</p>
              )}
            </div>

            <div className="form-group">
              <label for="permit_issue_date">Issue Date</label>
              <input
                className="form-control"
                type="date"
                {...register("permit_issue_date", {
                  required: true,
                })}
              />
              {errors?.permit_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>

            <div className="form-group">
              <label for="permit_expiry_date">Expiry Date</label>
              <input
                className="form-control"
                type="date"
                {...register("permit_expiry_date", {
                  required: true,
                })}
              />
              {errors?.permit_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
          </li>
          <div style={{ clear: "both" }}></div> <hr />
          <li>
            <div className="form-group">
              <label for="truck_permit">Fitness Certificate</label>
              <input
                className="form-control"
                type="file"
                {...register("truck_fitness", {
                  required: true,
                })}
              />
              {errors?.truck_fitness?.type === "required" && (
                <p className="error_p">Kindly Upload Fitness Certificate</p>
              )}
            </div>

            <div className="form-group">
              <label for="truck_fitness_number">Fitness Number</label>
              <input
                className="form-control"
                type="text"
                {...register("truck_fitness_number", {
                  required: true,
                })}
              />
              {errors?.truck_fitness_number?.type === "required" && (
                <p className="error_p">Fitness Number is required</p>
              )}
            </div>

            <div className="form-group">
              <label for="fitness_issue_date">Issue Date</label>
              <input
                className="form-control"
                type="date"
                {...register("fitness_issue_date", {
                  required: true,
                })}
              />
              {errors?.fitness_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>

            <div className="form-group">
              <label for="fitness_expiry_date">Expiry Date</label>
              <input
                className="form-control"
                type="date"
                {...register("fitness_expiry_date", {
                  required: true,
                })}
              />
              {errors?.fitness_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
          </li>
        </ul>

        <div style={{ clear: "both" }}></div>

        <div>
          <button className="mt-3 btn-back" onClick={handleBackStep}>
            <h1>
              <i className="bi bi-arrow-left-square-fill"></i>
            </h1>
          </button>
          <button type="submit" className="btn dark-btn mx-auto d-block mt-3">
            {loading ? <><Loader /> {" "}
              Submitting...</> : <>Submit</>}
          </button>

          {show && (
            <CustomizedSnackbars
              show={show}
              message={truckError}
              messageType={messageType}
            />
          )}
        </div>
      </form>
    </>
  );
};

export default TruckDocuments;

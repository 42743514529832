import React from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleBackdrop from '../../common/Spinner/Spinner';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import TanStackTable from '../../common/Table/TanStackTable';
import { createColumnHelper } from '@tanstack/react-table';
import Select from 'react-select';
import { BASE_URL } from '../../../config';
import { setLogout } from '../../../features/Login';
import TruckModal from './TruckModal';
function Truck() {
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [truckId, setTruckId] = useState();
  const [truckData, setTruckData] = useState([]);
  const [loading, setloading] = useState(true);
  const columnHelper = createColumnHelper();
  const [modalHandler, setModalHandler] = useState(false)
  const [truckStatus, setTruckStatus] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const truckOptions = [{ value: "Idle", label: "Idle" }, { value: "Active", label: "Active" }, { value: "Maintenance", label: "Maintenance" }]

  useEffect(() => {
    const query = {
      query: `query($firm_id:ID!,$truck_status:String){
                truck(firm_id:$firm_id,truck_status:$truck_status){
                  truck_number
                  truck_id
                  model
                  old_registration
                  new_registration
                  manufacturing_year
                  valid_upto
                  ownership
                  registration_date
                  fastag_number
                  rto
                  engine_number
                  chasis_number
                  truck_length
                  trolley_length
                  gross_weight
                  unloading_weight
                  payloading_weight
                  purchased_date
                  invoice
                  total_cost
                  model_type
                  body_type
                  truck_status
                  
                }
              }`,
      variables: {
        firm_id: firm_id,
        truck_status: truckStatus
      },
    };

    const headers = {
      'Authorization': `Bearer ${bearerToken}`,
      'Content-Type': 'application/json'
    }

    axios.post(BASE_URL, query, { headers })
      .then(response => {
        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout())
        }
        else {
          setTruckData(response.data?.data.truck)
          setTruckData(truckData => [...truckData].reverse());
          setloading(false);
        }
      }).catch(error => {
        setloading(false);
      })

  }, [firm_id, truckStatus])

  const columns = [

    columnHelper.accessor('truck_number', {
      header: 'Truck No.'
    }),
    columnHelper.accessor('truck_status', {
      header: 'Truck Status',
    }),
    columnHelper.accessor('model', {
      header: "Model"
    }),
    columnHelper.accessor('ownership', {
      header: 'Ownership',
    }),
    columnHelper.accessor('registration_date', {
      header: 'Registration Date ',
      cell: info => info.getValue().split("-").reverse().join("-")

    }),
    columnHelper.accessor('model_type', {
      header: 'Model Type',
    }),
    columnHelper.accessor('purchased_date', {
      header: 'Purchased_Date',
      cell: info => info.getValue().split("-").reverse().join("-")

    }),
    columnHelper.accessor('body_type', {
      header: 'Body Type',
    }),
    columnHelper.accessor('truck_id', {
      header: 'More Details',
      cell: (props) => <button value={props.getValue()} className='dark-btn rounded m-auto bi bi-eye' onClick={handleTruckModalShow}></button>
    })

  ];

  const handleTruckModalShow = (event) => {
    setModalHandler(true)
    setTruckId(event.target.value)

  }

  const ModelAction = (modalHandler) => {
    setModalHandler(modalHandler)
  }

  useEffect(() => { }, [modalHandler]);
  return (
    <>
      <div className='mb-4'
        style={{
          backgroundColor: "#10233c",
          color: "white",
          height: "40px",
          textAlign: "center",
        }}
      >
        <h4 className="pt-1">Truck Details</h4>
      </div>

      <button onClick={() => navigate("/addtruck")} disabled={firm_id ? false : true} className='btn dark-btn'>
        Add Truck
      </button>
      {!firm_id && <><p className='error_p mt-2'>Kindly, Register your firm...</p><br /></>}
      <hr />
      <div className='w-25 mb-3'>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isClearable
          onChange={(e) => setTruckStatus(e?.value)}
          options={truckOptions}
        />
      </div>

      {loading && truckData?.length == 0
        ?
        <SimpleBackdrop />
        : (truckData?.length > 0
          ? <>
            <TanStackTable data={truckData} columns={columns} />
          </>

          :
          <div className=' alert alert-info d-flex justify-content-center' role="alert">
            No Data to Show
          </div>

        )
      }

      {
        modalHandler && <TruckModal data={truckData} truckId={truckId} modalAction={ModelAction} modalHandler={modalHandler} />
      }

    </>
  )
}
export default Truck;

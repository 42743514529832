import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../config";

const DriverModal = (props) => {
  const [documentFile, setDocumentFile] = useState();
  const formData = props?.data;
  const modalData = formData?.find((el) => {
    return props?.driverId == el.driver_id;
  });

  const handleDocuments = (event, item) => {
    event.preventDefault();

    const driverDocumentQuery = {
      query: `query($driver_id:ID!,$type:String!){
            driverDocuments(driver_id:$driver_id,type:$type)
            {
              url
              type
            }
          }`,
      variables: {
        driver_id: event.target.value,
        type: item,
      },
    };
    axios
      .post(BASE_URL, driverDocumentQuery)
      .then((response) => {
        setDocumentFile(response.data?.data.driverDocuments?.url);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (documentFile) {
      window.open(documentFile, "_blank");
    }
    setTimeout(() => {
      setDocumentFile();
    }, 2000);
  }, [documentFile]);

  return (
    <>
      <Modal show={props?.modalHandler} size="xl" scrollable={true}>
        <Modal.Header
          className="mb-4"
          style={{
            backgroundColor: "#10233c",
            color: "white",
            height: "40px",
            textAlign: "center",
          }}
        >
          <h4 className=" my-0 m-auto">Driver Details</h4>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p style={{ color: "#F4801F" }} className="ms-2">
              <b>Personal Details - </b>
            </p>
            <Form.Group className="form-group">
              <Form.Label>Name</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_name_}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Father Name</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_father_name}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_phone_no}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_dob
                  .split("-")
                  .reverse()
                  .join("-")}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Blood Group</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_blood_group}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Aadhar Number</Form.Label>
              <div className="d-flex">
                <Form.Control
                  style={{
                    borderRadius: "0px",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                  disabled
                  defaultValue={modalData?.driver_aadhaar}
                  type="text"
                />
                <div className="py-0 px-0 form_icon_icon">
                  <button
                    className="dark-btn h-100 bi bi-filetype-doc"
                    value={modalData?.driver_id}
                    onClick={(event) => handleDocuments(event, "aadhar")}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Address</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_address}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>City</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_city}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>State</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_state}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Pin Code</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_pin}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Joining Date</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_join_date
                  .split("-")
                  .reverse()
                  .join("-")}
                type="text"
              />
            </Form.Group>
            <div style={{ clear: "both" }}></div>
            <hr />

            <p style={{ color: "#F4801F" }} className="ms-2">
              <b> Licence Details </b>
            </p>
            <Form.Group className="form-group">
              <Form.Label>Licence Number</Form.Label>
              <div className="d-flex">
                <Form.Control
                  disabled
                  style={{
                    borderRadius: "0px",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                  defaultValue={modalData?.driver_licence_no}
                  type="text"
                />
                <div className="py-0 px-0 form_icon_icon">
                  <button
                    className="dark-btn h-100 bi bi-filetype-doc"
                    value={modalData?.driver_id}
                    onClick={(event) => handleDocuments(event, "licence")}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Licence Type</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.driver_class}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Issue Date</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.issue_date
                  .split("-")
                  .reverse()
                  .join("-")}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Valid Upto</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.valid_upto
                  .split("-")
                  .reverse()
                  .join("-")}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Authority</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.authority}
                type="text"
              />
            </Form.Group>

            <div style={{ clear: "both" }}></div>
            <hr />
            <p style={{ color: "#F4801F" }} className="ms-2">
              <b> Reference Details </b>
            </p>

            <Form.Group className="form-group">
              <Form.Label>Reference Name</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.r_name}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Relation</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.r_relation}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.r_phone_no}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Address</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.r_address}
                type="text"
              />
            </Form.Group>

            <div style={{ clear: "both" }}></div>
            <hr />

            <p style={{ color: "#F4801F" }} className="ms-2">
              <b> Bank Details </b>
            </p>

            <Form.Group className="form-group">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.bank_name}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.ac_no}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.ifsc}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Beneficiary Name</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.beneficiary}
                type="text"
              />
            </Form.Group>
            <div style={{ clear: "both" }}></div>
            <hr />

            <p style={{ color: "#F4801F" }} className="ms-2">
              <b> Driver Documents </b>
            </p>
            <ul>
              <li>
                <b>AADHAR CARD</b> -{" "}
                <button
                  className="btn btn-success bi bi-filetype-doc ms-5"
                  value={modalData?.driver_id}
                  onClick={(event) => handleDocuments(event, "aadhar")}
                ></button>
              </li>
              <br />
              <li>
                <b className="me-5">LICENCE</b> -{" "}
                <button
                  className="btn btn-success mx-5 bi bi-filetype-doc"
                  value={modalData?.driver_id}
                  onClick={(event) => handleDocuments(event, "licence")}
                ></button>
              </li>
            </ul>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => props?.modalAction(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DriverModal;

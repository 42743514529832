import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import TanStackTable from '../../common/Table/TanStackTable';
import { createColumnHelper } from '@tanstack/react-table';
import SimpleBackdrop from '../../common/Spinner/Spinner';
import { BASE_URL } from '../../../config';

const InvoiceViewModal = (props) => {

    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const [tableContent, setTableContent] = useState([]);
    const [invoiceContent, setInvoiceContent] = useState();
    const [truckTrip, setTruckTrip] = useState();
    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    };
    const columnHelper = createColumnHelper();
    const date = truckTrip?.trip_date;
    const new_date = date?.split("-").reverse().join("-");

    useEffect(() => {
        // ===== Query to get Invoice Details =============
        const query1 = {
            query: `query($trip_id:ID!){
                tripInvoice(trip_id:$trip_id) {
                    trip_id
                    trip_invoice_id
                    package_quantity
                    total_weight
                    rate
                    freight
                    total_amount
                    remaining
                    gst_applied
                    lr_number
                    shortage_amount
                    
                  }
              } `,
            variables: {
                trip_id: props?.tripId
            }
        }
        // ===== Query to get Trip Details =============

        const query2 = {
            query: `query($trip_id:ID!){
                truckTrip(trip_id:$trip_id){
                  trip_date
                  trip_id
                  trip_consigner
                  trip_consignee
                  trip_product
                  trip_source
                  trip_destination
                  trip_invoice_number
                  trip_invoice_value
                  truck{
                    truck_number
                  }
                }
              }`,
            variables: {
                trip_id: props?.tripId
            }
        }
        // ====== Query to get Part Payment of Invoice
        const query3 = {
            query: `query($trip_invoice_id:ID!){
                tripInvoicePayment(trip_invoice_id:$trip_invoice_id){
                    invoice_payment_id
                    payment_type
                    amount_paid
                }
            }`,
            variables: {
                trip_invoice_id: props?.paymentId
            }
        }

        axios
            .post(BASE_URL, query1, { headers })
            .then((response) => {
                setInvoiceContent(response?.data.data.tripInvoice?.[0])
            })
            .catch((error) => [
            ])

        axios
            .post(BASE_URL, query2, { headers })
            .then((response) => {
                setTruckTrip(response?.data.data.truckTrip?.[0])
            })
            .catch((error) => [
            ])

        axios
            .post(BASE_URL, query3, { headers })
            .then((response) => {
                setTableContent(response?.data.data?.tripInvoicePayment)
            })
            .catch((error) => {
            })

    }, [props])

    const tableColumns = [
        columnHelper.accessor('payment_type', {
            header: 'Payment Type'
        }),
        columnHelper.accessor('amount_paid', {
            header: 'Amount Paid',
            cell: info => '₹ ' + info.getValue(),
        }),
    ]

    return (<>

        <Modal
            show={props?.modalHandler}
            size="xl"
            scrollable={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                className='mb-4'
                style={{
                    backgroundColor: "#10233c",
                    color: "white",
                    height: "40px",
                    textAlign: "center",
                }}
            >
                <h4 className=" my-0 m-auto">Invoice Details</h4>
            </Modal.Header>
            {tableContent && invoiceContent && truckTrip
                ?
                <Modal.Body>
                    <Form>

                        <Form.Group className="form-group">
                            <Form.Label>Trip Date</Form.Label>
                            <Form.Control disabled defaultValue={new_date}
                                type="text"
                            />
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Truck Number</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.truck?.truck_number}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>LR Number</Form.Label>
                            <Form.Control disabled defaultValue={invoiceContent?.lr_number}
                                type="text"
                            />
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Consigner</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_consigner}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Consignee</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_consignee}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Invoice Number</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_invoice_number}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Source</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_source}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Destination</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_destination}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Invoice Value</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_invoice_value}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Product</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_product}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Package Quantity</Form.Label>
                            <Form.Control disabled defaultValue={invoiceContent?.package_quantity}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Freight</Form.Label>
                            <div className="d-flex">
                                <div className="py-2 px-3 form_icon_icon">
                                    <i className="bi bi-currency-rupee"></i>
                                </div>
                                <input disabled
                                    className="form-control_icon diabledControl"
                                    defaultValue={invoiceContent?.freight}
                                    type="text"
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Total Amount</Form.Label>
                            <div className="d-flex">
                                <div className="py-2 px-3 form_icon_icon">
                                    <i className="bi bi-currency-rupee"></i>
                                </div>
                                <input
                                    className="form-control_icon diabledControl"
                                    disabled defaultValue={invoiceContent?.total_amount}
                                    type="text"
                                />
                            </div>
                            {invoiceContent?.gst_applied == true && (
                                <p className="error_p"> 18% GST applied on Freight</p>
                            )}
                        </Form.Group>
                        {invoiceContent?.remaining > 0
                            &&
                            <Form.Group className="form-group">
                                <Form.Label>Remaining</Form.Label>
                                <div className="d-flex">
                                    <div className="py-2 px-3 form_icon">
                                        <i className="bi bi-currency-rupee"></i>
                                    </div>
                                    <Form.Control disabled defaultValue={invoiceContent?.remaining}
                                        type="text"
                                    />
                                </div>
                            </Form.Group>
                        }
                        {invoiceContent?.shortage_amount
                            &&
                            <Form.Group className="form-group">
                                <Form.Label>Shortage Amount</Form.Label>
                                <div className="d-flex">
                                    <div className="py-2 px-3 form_icon">
                                        <i className="bi bi-currency-rupee"></i>
                                    </div>
                                    <Form.Control disabled defaultValue={invoiceContent?.shortage_amount}
                                        type="text"
                                    />
                                </div>
                            </Form.Group>
                        }

                    </Form>

                    <div style={{ clear: 'both' }}></div>
                    <hr />
                    {tableContent &&
                        <TanStackTable data={tableContent} columns={tableColumns} />}


                </Modal.Body>
                : <div className='m-5 p-5 ' >
                    <SimpleBackdrop />
                </div>}
            <Modal.Footer>
                <Button variant="dark" onClick={() => props?.modalAction(false)} >
                    Close
                </Button>

            </Modal.Footer>
        </Modal>

    </>
    )
}

export default InvoiceViewModal;
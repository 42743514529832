// import '../Driver/AddDriver.css'
import React from 'react';
import { Step, Stepper, StepLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import TruckDetails from './TruckDetails';
import TruckDocuments from './TruckDocuments';

function AddTruck() {
    const step = useSelector((state) => state?.truckFormReducer?.addTruckStep);
    const showStep = (key) => {
        switch (key) {
            case 1:
                return <TruckDetails />

            case 2:
                return <TruckDocuments />

        }
    }

    return (
        <>
            <div
                className='mb-5'
                style={{
                    backgroundColor: "#10233c",
                    color: "white",
                    height: "40px",
                    textAlign: "center",
                }}
            >
                <h4 className="pt-1">Add New Truck</h4>
            </div>
            <div className='align-items-center'>
                <Stepper style={{ width: '100%', }} activeStep={step - 1} orientation='horizontal' alternativeLabel>
                    <Step>
                        <StepLabel>Truck Details</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel> Truck Documents</StepLabel>
                    </Step>
                </Stepper>
            </div>

            <div > {showStep(step)}</div>
        </>
    );
}

export default AddTruck;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import SimpleBackdrop from '../../common/Spinner/Spinner';
import { createColumnHelper } from '@tanstack/react-table';
import TanStackTable from '../../common/Table/TanStackTable';
import { Button } from 'react-bootstrap';
import { BASE_URL } from '../../../config';
import { setLogout } from '../../../features/Login';


function Firms() {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const userId = useSelector((state) => state?.loginDetail?.userDetail?.id);
  const dispatch = useDispatch();
  const [content, setContent] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState("");
  const columnHelper = createColumnHelper();
  const [url, setUrl] = useState();

  useEffect(() => {

    const headers = {
      'Authorization': `Bearer ${bearerToken}`,
      'Content-Type': 'application/json'
    };
    const query = {
      query: `query($user_id:ID!){
        firm(user_id:$user_id){
          firm_id
          firm_name
          firm_email
          firm_contact
          firm_address
          firm_pancard
          firm_gstin
          lr_prefix
          lr_suffix
          firm_licence
          firm_cin
        }
      }`,
      variables: {
        user_id: userId,
      }
    }


    axios.post(BASE_URL, query, { headers })
      .then(response => {
        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout())
        }
        if (response.data?.data.firm.length != 0) {
          setContent(response.data?.data.firm);
          setLoading(false);
        }

        if (response.data.type == "failed") {
          setLoading(false);
        }
        setLoading(false);
      })
      .catch(error => {
        setIsError(error.message);
        setLoading(false);
      });


  }, [page])

  const columns = [
    columnHelper.accessor('firm_name', {
      header: 'Name'
    }),
    columnHelper.accessor('firm_contact', {
      header: 'Contact'
    }),
    columnHelper.accessor('firm_email', {
      header: 'E-Mail'
    }),
    columnHelper.accessor('firm_address', {
      header: 'Address'
    }),
    columnHelper.accessor('firm_gstin', {
      header: 'GST'
    }),
    columnHelper.accessor('firm_pancard', {
      header: 'Pancard'
    }),
    columnHelper.accessor('firm_cin', {
      header: 'CIN'
    }),
    columnHelper.accessor('lr_prefix', {
      header: 'LR Prefix',
      cell: info => {
        let data = info.getValue()
        return data != null ? data : "-"
      },
    }),
    columnHelper.accessor('lr_suffix', {
      header: 'LR Suffix',
      cell: info => {
        let data = info.getValue()
        return data != null ? data : "-"
      },
    }),
    columnHelper.accessor('firm_id', {
      header: 'Licence',
      cell: (props) => <button type="button" value={props.getValue()} className="dark-btn m-auto bi bi-eye rounded" onClick={handleDownload}></button>
    }),
  ];

  const handleDownload = (event) => {

    const FirmLicenceQuery = {
      query: `query($firm_id:ID!){
        firmLicence(firm_id:$firm_id)
        {
          url
        }
      }`,
      variables: {
        firm_id: event.target.value
      }
    }

    axios.post(BASE_URL, FirmLicenceQuery)
      .then((response) => {
        setUrl(response.data?.data.firmLicence?.url)
      })
      .catch((error) => {
      })
  }
  useEffect(() => {
    if (url) {
      window.open(url, '_blank');

      setTimeout(() => {
        setUrl();
      }, 3000)
    }

  }, [url])



  return (
    <>
      <div className='mb-4'
        style={{
          backgroundColor: "#10233c",
          color: "white",
          height: "40px",
          textAlign: "center",
        }}
      >
        <h4 className="pt-1">Firm Details</h4>
      </div>

      <Link to="/addfirms" className='btn dark-btn'>
        Add Firms
      </Link>
      <hr />
      {loading
        ?
        <SimpleBackdrop />
        :
        (content?.length > 0
          ?
          <TanStackTable data={content} columns={columns} />
          :
          <div className=' alert alert-info d-flex justify-content-center' role="alert">
            No Data to Show
          </div>
        )

      }

    </>
  )
}

export default Firms;
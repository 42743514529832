import React, { useEffect, useState } from "react";
import { setDecrem, setSubmit } from "../../../features/TruckForm";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASE_URL } from "../../../config";
import CustomizedSnackbars from "../../common/Popup/Snackbar";
import Loader from "../../common/Spinner/Loader";
function InvoiceDetail() {
  const dispatch = useDispatch();
  let truckDetails = useSelector((state) => state?.truckFormReducer?.truckDetails);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const lr_prefix = useSelector(state => state?.loginDetail?.firmDetails?.lr_prefix);
  const lr_suffix = useSelector(state => state?.loginDetail?.firmDetails?.lr_suffix);
  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState();
  const [show, setShow] = useState(false);
  const [totalWeight, setTotalWeight] = useState();
  const [rateTonne, setRateTonne] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [applyGst, setApplyGst] = useState(false);
  const [rate, setRate] = useState(false);
  const [freight, setFreight] = useState();
  const [remain, setRemain] = useState();
  const [advance, setAdvance] = useState('');
  const [ad_paid, setAd_paid] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const headers = {
    Authorization: `Bearer ${bearerToken} `,
    "Content-Type": "application/json",
  };


  useEffect(() => {

    if (rate) {
      setFreight(totalWeight * rateTonne);

      if (applyGst) {
        setTotalAmount(freight * 1.12);
        setRemain(totalAmount - advance);
      } else {
        setTotalAmount(freight);
        setRemain(totalAmount - advance);
      }
      if (!advance) {

        setRemain(totalAmount)
      }
    } else {
      if (applyGst) {
        setTotalAmount(freight * 1.18);
        setRemain(totalAmount - advance);
      } else {
        setTotalAmount(freight);
        setRemain(totalAmount - advance);
      }
      if (!advance) {

        setRemain(totalAmount)
      }
    }
  }, [
    rateTonne,
    applyGst,
    rate,
    totalAmount,
    freight,
    remain,
    advance,
    totalWeight,
  ]);

  const onSubmit = (data) => {
    setLoading(true)
    if (!advance) {
      setAdvance(0)
    }

    for (let key in data) {
      if (key == "advance_paid") {
        let x = key
        let y = +data[key]
        truckDetails = { ...truckDetails, "payment_type": x, "amount_paid": y }
      }
    }

    const query = {
      query: `mutation(
                  $truck_id:Int!,
                  $trip_date:Date!,
                  $trip_consigner:String!,
                  $trip_consignee:String!,
                  $trip_product:String!,
                  $trip_source:String!,
                  $trip_destination:String!,
                  $trip_invoice_number:String!,
                  $trip_invoice_value:String!,
                  $package_quantity:String!,
                  $total_weight:String!,
                  $rate:String!,
                  $freight:Int!,
                  $total_amount:Int!,
                  $remaining:Int
                  $gst_applied:Boolean!,
                  $lr_prefix:String,
                  $lr_suffix:String
                  $payment_type:String!,
                  $amount_paid:Int!){
             addTruckTrip(
                    truck_id:$truck_id,
                    trip_date:$trip_date,
                    trip_consigner:$trip_consigner,
                    trip_consignee:$trip_consignee,
                    trip_product:$trip_product,
                    trip_source:$trip_source,
                    trip_destination:$trip_destination,
                    trip_invoice_number:$trip_invoice_number,
                    trip_invoice_value:$trip_invoice_value,
                    
                    package_quantity:$package_quantity,
                    total_weight:$total_weight,
                    rate:$rate,
                    freight:$freight,
                    total_amount:$total_amount,
              
                    remaining:$remaining,
                    gst_applied:$gst_applied,

                    lr_prefix:$lr_prefix,
                    lr_suffix:$lr_suffix

                    payment_type:$payment_type,
                    amount_paid:$amount_paid
                  ){
                    message
                    type
                    error{
                      truck_id,
                      trip_date,
                      trip_consigner,
                      trip_consignee,
                      trip_product,
                      trip_source,
                      trip_destination,
                      trip_invoice_number,
                      trip_invoice_value
                      gst_applied
                      trip_expense_date
  
                      package_quantity
                      total_weight
                      rate
                      freight
                      total_amount
                      remaining

                    }

                  }

                }`,
      variables: {
        truck_id: +truckDetails.truck_id,
        trip_date: truckDetails.trip_date,
        trip_consigner: truckDetails.trip_consigner,
        trip_consignee: truckDetails.trip_consignee,
        trip_product: truckDetails.trip_product,
        trip_source: truckDetails.trip_source,
        trip_destination: truckDetails.trip_destination,
        trip_invoice_number: truckDetails.trip_invoice_number,
        trip_invoice_value: truckDetails.trip_invoice_value,
        package_quantity: data.package_quantity,
        total_weight: data.total_weight,
        rate: data.rate,
        freight: +data.freight,
        total_amount: data.total_amount,
        remaining: data?.remaining,
        gst_applied: applyGst,
        lr_prefix: lr_prefix,
        lr_suffix: lr_suffix,

        payment_type: truckDetails.payment_type,
        amount_paid: truckDetails.amount_paid

      }
    }
    axios.post(BASE_URL, query, { headers })
      .then((response) => {
        setLoading(false)
        setMessage(response.data?.data.addTruckTrip.message)
        setMessageType(response.data?.data.addTruckTrip.type)
        setShow(true);
      })
      .catch((error) => {
        setLoading(false)
      })
    reset();
    setTimeout(() => {
      dispatch(setSubmit());
    }, 3000)

  };
  const BackStep = () => {
    dispatch(setDecrem());
  };

  const handleRate = () => {
    if (rate) {
      setRate(false);
      resetState();
    } else {
      setRate(true);
      resetState();
    }
  };
  const resetState = () => {
    setAdvance();
    setFreight();
    setTotalAmount();
    setRemain();
    setApplyGst(false);
    setTotalWeight();
    setRateTonne();
    reset();
  };
  useEffect(() => {
    setValue("total_amount", totalAmount);
    setValue("remaining", remain);
    setValue("freight", freight);
    if (advance > totalAmount) {
      setValue("advance_paid", 0)
    }
  }, [totalAmount, remain, freight, advance]);


  useEffect(() => { }, [applyGst])

  const handleAdvance = (e) => {
    if (+e.target.value > +(totalAmount)) {
      setAd_paid(true)
    }
    else {
      setAdvance(+(e?.target.value))
      setAd_paid(false);
    }
  }

  return (
    <>
      <div className="addtruck-form mt-5 p-2">
        <Form className="" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Check
              checked={rate ? true : false}
              onClickCapture={handleRate}
              label="Custom Rate"
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              checked={applyGst ? true : false}
              onChange={() => {
                applyGst == false ? setApplyGst(true) : setApplyGst(false);
              }}
              label=" Apply GST "
            />
          </Form.Group>
          <hr />

          <Form.Group className="form-group">
            <Form.Label>Package Quantity</Form.Label>
            <Form.Control
              type="text"
              {...register("package_quantity", {
              })}
            />
            {errors?.package_quantity?.type === "required" && (
              <p className="error_p">
                Package Quantity is required is required
              </p>
            )}
          </Form.Group>
          <Form.Group className="form-group" hidden={rate ? false : true}>
            <Form.Label>Total Weight/tonne</Form.Label>
            <Form.Control
              type="text"
              onChangeCapture={(e) => setTotalWeight(+e.target.value)}
              {...register("total_weight", {
              })}
            />
            {errors?.total_weight?.type === "required" && (
              <p className="error_p">Total Weight is required</p>
            )}
          </Form.Group>
          <Form.Group className="form-group" hidden={rate ? false : true}>
            <Form.Label>Rate/tonne</Form.Label>
            <Form.Control
              type="text"
              onChangeCapture={(e) => setRateTonne(+e.target.value)}
              {...register("rate", {
              })}
            />
            {errors?.rate?.type == "required" && (
              <p className="error_p">Rate is required</p>
            )}
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Freight</Form.Label>
            <Form.Control
              type="text"
              defaultValue={freight}
              disabled={rate ? true : false}
              onChangeCapture={(e) => setFreight(+(e.target.value))}
              {...register("freight", {
              })}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Total Payable Amount</Form.Label>
            <Form.Control
              type="text"
              defaultValue={totalAmount}
              disabled
              {...register("total_amount", {
              })}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Advance Paid</Form.Label>
            <Form.Control
              type="text"
              value={advance}
              onChangeCapture={handleAdvance}
              {...register("advance_paid", {
              })}
            />
            {ad_paid && <p className="error_p"> Can not pay more than Total Payable Amount</p>}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Remaining</Form.Label>
            <Form.Control
              type="number"
              disabled
              defaultValue={remain}
              {...register("remaining", {
                //  required: true
              })}
            />
          </Form.Group>
          <div style={{ clear: "both" }}></div>
          <div className="d-flex justify-content-between">
            <button className="mt-3 btn-back " onClick={BackStep}>
              <h1>
                <i className="bi bi-arrow-left-square-fill"></i>
              </h1>
            </button>
            <div>
              <button className="btn-submit mx-3" type="submit">
                {loading ? <> <Loader /> {" "} Submitting...</> : <>Submit</>}
              </button>
              <button onClick={() => resetState()} className="btn-submit ">
                RESET
              </button>
            </div>
          </div>
        </Form>
        {show && <CustomizedSnackbars show={show} message={message} messageType={messageType} />}
      </div>
    </>
  );
}

export default InvoiceDetail;

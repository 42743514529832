import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import SimpleBackdrop from '../../common/Spinner/Spinner';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import TanStackTable from '../../common/Table/TanStackTable';
import { BASE_URL } from '../../../config';
import { setLogout } from '../../../features/Login';
import DriverModal from './DriverModal';

function Drivers() {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const driverModalShow = useSelector((state) => state?.driverFormReducer?.driverModalShow);
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const [content, setContent] = useState([]);
  const [driverId, setDriverId] = useState();
  const [loading, setLoading] = useState(true);
  const columnHelper = createColumnHelper();
  const [modalHandler, setModalHandler] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headers = {
    'Authorization': `Bearer ${bearerToken}`,
    'Content-Type': 'application/json'
  };

  useEffect(() => {

    const query = {
      query: `query($firm_id:ID!){
      driver(firm_id:$firm_id){
 
        firm_id
        driver_id
        driver_type_
        driver_name_ 
        driver_father_name 
        driver_dob 
        driver_join_date
        driver_aadhaar
        driver_address
        driver_phone_no
        driver_city
        driver_state
        driver_pin
        driver_blood_group
        driver_blacklisted
        driver_block_reason
        driver_licence_no
        driver_class
        issue_date
        valid_upto
        authority
        r_name
        r_relation
        r_address
        r_phone_no
        bank_name
        ac_no
        ifsc
        beneficiary
        driver_uploaded_aadhar
        driver_uploaded_licence
      }
    }`,
      variables: {
        firm_id: firm_id
      }
    }

    axios.post(BASE_URL, query, { headers })
      .then(response => {

        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout())
        }
        else {
          setContent(response.data?.data.driver);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
      });


  }, [firm_id])

  const columns = [
    columnHelper.accessor('driver_name_', {
      header: 'Name'
    }),
    columnHelper.accessor('driver_father_name', {
      header: 'Father Name'
    }),
    columnHelper.accessor('driver_dob', {
      header: 'Date of Birth',
      cell: info => info.getValue().split("-").reverse().join("-")
    }),
    columnHelper.accessor('driver_phone_no', {
      header: 'Contact'
    }),
    columnHelper.accessor('driver_licence_no', {
      header: 'Licence No.'
    }),
    columnHelper.accessor('driver_aadhaar', {
      header: 'Aadhar No.'
    }),
    columnHelper.accessor('driver_city', {
      header: 'City'
    }),
    columnHelper.accessor('driver_id', {
      header: 'More Details',
      cell: (props) => <button type="button" value={props.getValue()} className="dark-btn rounded m-auto bi bi-eye" onClick={(e) => handleMoreDetailsView(e)}></button>
    }),
  ];

  const handleMoreDetailsView = (e) => {
    setModalHandler(true)
    setDriverId(e.target.value)

  }

  const ModelAction = (modalHandler) => {
    setModalHandler(modalHandler)
  }
  useEffect(() => { }, [modalHandler]);

  return (<>

    <div className='mb-4'
      style={{
        backgroundColor: "#10233c",
        color: "white",
        height: "40px",
        textAlign: "center",
      }}
    >
      <h4 className="pt-1">Driver Details</h4>
    </div>

    <button onClick={() => navigate("/adddriver")} disabled={firm_id ? false : true} className='btn dark-btn'>
      Add Driver
    </button>
    {!firm_id && <><p className='error_p mt-2'>Kindly, Register your firm...</p><br /></>}


    <hr />

    {loading
      ?
      <SimpleBackdrop />
      :
      (content?.length > 0
        ?
        <TanStackTable data={content} columns={columns} />
        :
        <div className=' alert alert-info d-flex justify-content-center' role="alert">
          No Data to Show
        </div>
      )
    }

    {modalHandler && <DriverModal driverId={driverId} modalHandler={modalHandler} modalAction={ModelAction} data={content} />}
  </>)
}

export default Drivers;
import { HashRouter } from 'react-router-dom';
import AllRoutes from "./AllRoutes";

function App() {

  return (
    <>
      <HashRouter>
        <AllRoutes />
      </HashRouter>

    </>
  );
}

export default App;

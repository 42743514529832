import React, { useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../config";
  
const ExpiryModal = (props) => {
  const [url, setUrl] = useState();
  const driverExpiryData = props?.driverExpiryData;
  const puc = props?.expiryPUC
  const insurance = props?.expiryInsurance
  const permit = props?.expiryPermit
  const fitness = props?.expiryFitness

  const handleLicenceView = (event, item) => {
    event.preventDefault();
    const driverDocumentQuery = {
    query: `query($driver_id:ID!,$type:String!){
       driverDocuments(driver_id:$driver_id,type:$type)
       {
         url
         type
       }
     }`,
    variables: {
        driver_id: event.target.value,
        type: item
      }
    }
    axios.post(BASE_URL, driverDocumentQuery)
      .then((response) => {
        setUrl(response.data?.data.driverDocuments?.url)
      })
      .catch((error) => {

      })
  }

  useEffect(() => {
    if (url) {
      window.open(url, '_blank');
    } setTimeout(() => {
      setUrl()
    }, 2000)
  }, [url])

  return (
    <>
      <Modal show={props.modalHandler} size="lg" scrollable>
        <Modal.Header className="imp_alert">
          <div className="d-flex ">
            <b className="bi bi-bell-fill pe-2"> </b>
            <b className="header_info">IMPORTANT EXPIRY ALERTS</b>
          </div>
        </Modal.Header>

        {
          <Modal.Body>
            <Tabs
              defaultActiveKey="licence"
              id="justify-tab-example"
              className="mb-3 custom_expiry_tab"
              justify
            >
              <Tab eventKey="licence" title="Licence" >
                {driverExpiryData.length
                  ?
                  driverExpiryData?.map((el) => {
                    return (
                      <div className="border p-2 mb-3 tab_bg d-flex justify-content-between">
                        <span>
                          Licence No. <b>{el?.driver_licence_no}</b> of driver{" "}
                          <b>{el?.driver_name_}</b> is going to expire on{" "}
                          <b>{(el?.valid_upto).split("-").reverse().join("-")}...</b>{" "}
                          <img src="/giphy6.gif" className="alert_gif" />
                        </span>
                        <span>
                          <button className="btn bi bi-eye" value={el?.driver_id} onClickCapture={(event) => handleLicenceView(event, 'licence')}></button>
                        </span>
                      </div>
                    );
                  })
                  :
                  <div className=' alert alert-info d-flex justify-content-center' role="alert">
                    No Data to Show
                  </div>}
              </Tab>
              <Tab eventKey="PUC" title="PUC">
                {puc.length
                  ?
                  puc?.map((el) => {
                    return (
                      <div className="border p-2 mb-3 tab_bg d-flex justify-content-between">
                        <span>
                          PUC No. <b>{el?.document_number}</b> of Truck{" "}
                          <b>{el?.truck_number}</b> is going to expire on{" "}
                          <b>{(el?.expiry_date).split("-").reverse().join("-")}...</b>{" "}
                          <img src="/giphy6.gif" className="alert_gif" />
                        </span>
                      </div>
                    );
                  })
                  :
                  <div className=' alert alert-info d-flex justify-content-center' role="alert">
                    No Data to Show
                  </div>
                }
              </Tab>
              <Tab eventKey="insurance" title="Insurance">
                {insurance.length
                  ?
                  insurance?.map((el) => {
                    return (
                      <div className="border p-2 mb-3 tab_bg d-flex justify-content-between">
                        <span>
                          Insurance Policy No. <b>{el?.document_number}</b> of Truck{" "}
                          <b>{el?.truck_number}</b> is going to expire on{" "}
                          <b>{(el?.expiry_date).split("-").reverse().join("-")}...</b>{" "}
                          <img src="/giphy6.gif" className="alert_gif" />
                        </span>
                      </div>
                    );
                  })
                  :
                  <div className=' alert alert-info d-flex justify-content-center' role="alert">
                    No Data to Show
                  </div>}
              </Tab>
              <Tab eventKey="Permit" title="Permit">
                {permit.length
                  ?
                  permit?.map((el) => {
                    return (
                      <div className="border p-2 mb-3 tab_bg d-flex justify-content-between">
                        <span>
                          Permit Certificate <b>{el?.document_number}</b> of Truck{" "}
                          <b>{el?.truck_number}</b> is going to expire on{" "}
                          <b>{(el?.expiry_date).split("-").reverse().join("-")}...</b>{" "}
                          <img src="/giphy6.gif" className="alert_gif" />
                        </span>
                      </div>
                    );
                  })
                  :
                  <div className=' alert alert-info d-flex justify-content-center' role="alert">
                    No Data to Show
                  </div>}
              </Tab>
              <Tab eventKey="fitness" title="Fitness">
                {fitness.length
                  ?
                  fitness?.map((el) => {
                    return (

                      <div className="border p-2 mb-3 tab_bg d-flex justify-content-between">
                        <span>
                          Fitness Certificate <b>{el?.document_number}</b> of Truck{" "}
                          <b>{el?.truck_number}</b> is going to expire on{" "}
                          <b>{(el?.expiry_date).split("-").reverse().join("-")}...</b>{" "}
                          <img src="/giphy6.gif" className="alert_gif" />
                        </span>
                      </div>
                    );
                  })
                  :
                  <div className=' alert alert-info d-flex justify-content-center' role="alert">
                    No Data to Show
                  </div>}
              </Tab>
            </Tabs>
          </Modal.Body>
        }

        <Modal.Footer>
          <button type="button" className="btn btn-dark" onClick={() => props?.modalAction(false)}>
            close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExpiryModal;

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import "./Dashboard.css"
import moment from 'moment/moment';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import ExpiryModal from './ExpiryModal';

function Dashboard() {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const [driverDocuments, setDriverDocuments] = useState([]);
  const [truckDocument, setTruckDocument] = useState([]);
  const [expiryPUC, setExpiryPUC] = useState([]);
  const [expiryInsurance, setExpiryInsurance] = useState([]);
  const [expiryPermit, setExpiryPermit] = useState([]);
  const [expiryFitness, setExpiryFitness] = useState([]);
  const [driverExpiryData, setDriverExpiryData] = useState([]);
  const [modalHandler, setModalHandler] = useState(false);

  const headers = {
    'Authorization': `Bearer ${bearerToken}`,
    'Content-Type': 'application/json'
  };

  const handleExpiryModal = () => {
    setModalHandler(true);
  }
  const ModalAction = (item) => {
    setModalHandler(item)
  }

  useEffect(() => {

    // ===== Query to get driver licence expiry  =========
    const driverQuery = {
      query: `query($firm_id:ID!){
        driver(firm_id:$firm_id){
          driver_id
          driver_name_
          driver_licence_no
          driver_id
          valid_upto
        }
      }`,
      variables: {
        firm_id: firm_id
      }
    }
    axios.post(BASE_URL, driverQuery, { headers })
      .then((response) => {
        setDriverDocuments(response.data.data?.driver)
      })
      .catch((error) => {
      })

    //=========== Query to get Truck Docs Table ========
    const truckDocumentsQuery = {
      query: `query($firm_id:ID,$return_type:String){
    truckDocuments(firm_id:$firm_id,return_type:$return_type)
          {
            truck_id
            firm_id
            truck_number
            document_type
            document_number
            issue_date
            expiry_date
          }
      }`,
      variables: {
        firm_id: firm_id,
        return_type: "table"
      }
    }

    axios.post(BASE_URL, truckDocumentsQuery, { headers })
      .then((response) => {
        setTruckDocument(response.data?.data?.truckDocuments);
      })
      .catch((error) => {
      })
  }, [firm_id])



  useEffect(() => {

    const TodayDate = moment().format("YYYY-MM-DD");
    const maxDOB = moment().add(30, 'days').format("YYYY-MM-DD");

    setExpiryInsurance(truckDocument?.filter((el) => el.document_type === 'insurance').filter((el) => el?.expiry_date >= TodayDate && el?.expiry_date <= maxDOB));
    setExpiryPUC(truckDocument?.filter((el) => el.document_type === 'puc_certificate').filter((el) => el?.expiry_date >= TodayDate && el?.expiry_date <= maxDOB));
    setExpiryFitness(truckDocument?.filter((el) => el.document_type === 'fitness').filter((el) => el?.expiry_date >= TodayDate && el?.expiry_date <= maxDOB));
    setExpiryPermit(truckDocument?.filter((el) => el.document_type === 'permit').filter((el) => el?.expiry_date >= TodayDate && el?.expiry_date <= maxDOB));
    setDriverExpiryData(driverDocuments?.filter((el) => el?.valid_upto >= TodayDate && el?.valid_upto <= maxDOB));

  }, [driverDocuments, firm_id, truckDocument]);



  return (
    <>

      {driverExpiryData && modalHandler &&
        <ExpiryModal modalHandler={modalHandler} modalAction={ModalAction} driverExpiryData={driverExpiryData} expiryPUC={expiryPUC} expiryInsurance={expiryInsurance} expiryPermit={expiryPermit} expiryFitness={expiryFitness} />
      }
      <div className='alert_box' type='button' onClick={handleExpiryModal}>
        Alerts
      </div>
      <div classNameName='m-auto h-100'>
        <marquee width="100%" direction="left" height="100%" ><h3 classNameName='p-5 text-center'>Hola! this is a Demo Dash board page</h3></marquee>
      </div>
    </>
  )
}

export default Dashboard
import React, { useState, useEffect } from "react";
import "./AddTruck.css";
import "../../../common/Form/Form.css";
import "../AddTruck/AddTruck.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setIncrem } from "../../../../features/TruckForm";
import { capitalizeInput } from "../../../common/FunctionsC/functionsC";

function TruckDetails() {
  const TruckDetails = useSelector(
    (state) => state?.truckFormReducer?.addTruckDetails
  );
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [modelType, setModelType] = useState();


  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });


  const onSubmit = (data) => {
    dispatch(setIncrem(data));

    const query = {
      query: `mutation(
      $firm_id:ID!,

      $truck_number:String!,
      $truck_length:Int!,
      $trolley_length:Int!,
      $fastag_number:String!,
      $old_registration:String,
      $new_registration:String!,
      $invoice:Date!,
      $ownership:String!,
      $model:String!,
      $model_type:String!,
      $manufacturing_year:String!,
      $engine_number:String!,
      $total_cost:String!,
      $body_type:String!,
      $valid_upto:Date!,
      $gross_weight:Int!,
      $unloading_weight:Int!,
      $payloading_weight:Int!,
      $chasis_number:String!,
      $registration_date:Date!,
      $rto:String!,
      $purchased_date:Date!,
){
  addTruck( 
    firm_id:$firm_id,
    
    truck_number:$truck_number,
    model:$model,
    old_registration:$old_registration,
    new_registration:$new_registration,
    manufacturing_year:$manufacturing_year,
    rto:$rto,
    purchased_date:$ purchased_date,
    registration_date:$registration_date,
    model_type:$model_type,
    invoice:$invoice,
    truck_length:$truck_length,
    valid_upto:$valid_upto,
    ownership:$ownership,
    unloading_weight:$unloading_weight,
    payloading_weight:$payloading_weight,
    fastag_number:$fastag_number,
    body_type:$body_type,
    chasis_number:$chasis_number,
    engine_number:$engine_number,
    total_cost:$total_cost,
    trolley_length:$trolley_length,
    gross_weight:$gross_weight,
  )
  {
    error{
          truck_number
          model
          old_registration
          new_registration
          manufacturing_year
          valid_upto
          ownership
          registration_date
          fastag_number
          rto
          engine_number
          chasis_number
          truck_length
          trolley_length
          gross_weight
          unloading_weight
          payloading_weight
          purchased_date
          invoice
          total_cost
          model_type
          body_type
}
    message
    type
  }
}`,
      variables: {
        firm_id: data.firm_id,
        // driver_id: data.driver_id,
        truck_number: data.truck_number,
        model: data.model,
        old_registration: data.old_registration,
        new_registration: data.new_registration,
        manufacturing_year: data.manufacturing_year,
        valid_upto: data.valid_upto,
        ownership: data.ownership,
        registration_date: data.registration_date,
        fastag_number: data.fastag_number,
        rto: data.rto,
        engine_number: data.engine_number,
        chasis_number: data.chasis_number,
        truck_length: +data.truck_length,
        trolley_length: +data.trolley_length,
        gross_weight: +data.gross_weight,
        purchased_date: data.purchased_date,
        unloading_weight: +data.unloading_weight,
        payloading_weight: +data.payloading_weight,
        invoice: data.invoice,
        total_cost: data.total_cost,
        model_type: data.model_type,
        body_type: data.body_type,
      },
    };
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  const handleModelType = (e) => {
    setModelType(e.target.value);
  };

  const handleCapture = (e, item) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    if (item == "old_registration") {
      setValue("old_registration", result);
    } else if (item == "new_registration") {
      setValue("new_registration", result);
    } else if (item == "manufacturing_year") {
      setValue("manufacturing_year", result);
    } else if (item == "total_cost") {
      setValue("total_cost", result);
    } else if (item == "purchased_date") {
      setValue("purchased_datet", result);
    } else if (item == "payloading_weight") {
      setValue("payloading_weight", result);
    } else if (item == "unloading_weight") {
      setValue("unloading_weight", result);
    } else if (item == "gross_weight") {
      setValue("gross_weight", result);
    } else if (item == "trolley_length") {
      setValue("trolley_length", result);
    } else if (item == "truck_length") {
      setValue("truck_length", result);
    }
  };
  return (
    <>
      <form className="addtruck-form mt-5 p-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-div">
          <div className="form-group ">
            <label for="">Truck Number</label>
            <div className="d-flex">
              <div className="py-2 px-3 form_icon_icon font-weight-bold">
                <i className="bi bi-truck"></i>
              </div>
              <input
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value).toUpperCase())
                }
                defaultValue={TruckDetails?.truck_number}
                type="text"
                className="form-control_icon"
                id=""
                aria-describedby="emailHelp"
                {...register("truck_number", {
                  required: true,
                  pattern:
                    /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$|^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/,
                })}
              />
            </div>
            {errors?.truck_number?.type === "required" && (
              <p className="error_p">Truck number is required</p>
            )}
            {errors?.truck_number?.type === "pattern" && (
              <p className="error_p">Enter Valid Truck Number</p>
            )}
          </div>
          <div className="form-group">
            <label for="modaltype">Model Type</label>
            <select
              defaultValue={TruckDetails?.model_type}
              onChangeCapture={handleModelType}
              className="form-control"
              id="modaltype"
              {...register("model_type", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              <option>New</option>
              <option>Old</option>
            </select>
            {errors?.model_type?.type === "required" && (
              <p className="error_p">Model type is required</p>
            )}
          </div>
          <div className="form-group">
            <label for="model">Model</label>
            <select
              defaultValue={TruckDetails?.model}
              className="form-control"
              id="modal"
              {...register("model", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              <option>2016</option>
              <option>2017</option>
              <option>2018</option>
              <option>2019</option>
              <option>2020</option>
              <option>2021</option>
              <option>2022</option>
            </select>
            {errors?.model?.type === "required" && (
              <p className="error_p">Model field is required</p>
            )}
          </div>
          {modelType == "Old" && (
            <div className="form-group">
              <label for="">Old Registration</label>
              <input
                onChangeCapture={(e) => handleCapture(e, "old_registration")}
                defaultValue={TruckDetails?.old_registration}
                placeholder="YYYY"
                className="form-control"
                id=""
                {...register("old_registration", {
                  pattern: /[0-9]/g,
                  maxLength: 4,
                  minLength: 4,
                })}
              />
              {errors?.old_registration?.type === "pattern" && (
                <p className="error_p">
                  Old Registration year should be a number
                </p>
              )}
              {errors?.old_registration?.type === "maxLength" && (
                <p className="error_p">Not valid year</p>
              )}
              {errors?.old_registration?.type === "minLength" && (
                <p className="error_p">Not valid year</p>
              )}
            </div>
          )}

          <div className="form-group">
            <label for="">New Registration</label>
            <input
              // type="text"
              defaultValue={TruckDetails?.new_registration}
              onChangeCapture={(e) => handleCapture(e, "new_registration")}
              className="form-control"
              placeholder="YYYY"
              id=""
              {...register("new_registration", {
                required: true,
                pattern: /[0-9]/g,
                maxLength: 4,
                minLength: 4,
              })}
            />
            {errors?.new_registration?.type === "required" && (
              <p className="error_p">Registration number is required</p>
            )}
            {errors?.new_registration?.type === "pattern" && (
              <p className="error_p">
                New Registration year should be a number
              </p>
            )}
            {errors?.new_registration?.type === "maxLength" && (
              <p className="error_p">Not valid year</p>
            )}
            {errors?.new_registration?.type === "minLength" && (
              <p className="error_p">Not valid year</p>
            )}
          </div>
          <div className="form-group">
            <label for="">Manufacturing Year</label>
            <input
              defaultValue={TruckDetails?.manufacturing_year}
              placeholder="YYYY"
              className="form-control"
              onChangeCapture={(e) => handleCapture(e, "manufacturing_year")}
              id=""
              {...register("manufacturing_year", {
                required: true,
                pattern: /[0-9]/g,
                maxLength: 4,
                minLength: 4,
              })}
            />
            {errors?.manufacturing_year?.type === "required" && (
              <p className="error_p">Manufacture year is required</p>
            )}
            {errors?.manufacturing_year?.type === "pattern" && (
              <p className="error_p">Manufacture year should be a number</p>
            )}
            {errors?.manufacturing_year?.type === "maxLength" && (
              <p className="error_p">Not a valid year</p>
            )}

            {errors?.manufacturing_year?.type === "minLength" && (
              <p className="error_p">Not valid year</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Valid Upto</label>
            <input
              defaultValue={TruckDetails?.valid_upto}
              type="Date"
              className="form-control"
              id=""
              {...register("valid_upto", {
                required: true,
              })}
            />
            {errors?.valid_upto?.type === "required" && (
              <p className="error_p">Valid date is required</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Ownership</label>
            <input
              onInput={(e) => capitalizeInput(e)}
              defaultValue={TruckDetails?.ownership}
              type="text"
              className="form-control"
              id=""
              {...register("ownership", {
                required: true,
              })}
            />
            {errors?.ownership?.type === "required" && (
              <p className="error_p">ownership field is required</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Registration Date</label>
            <input
              defaultValue={TruckDetails?.registration_date}
              type="Date"
              className="form-control"
              id=""
              {...register("registration_date", {
                required: true,
              })}
            />
            {errors?.registration_date?.type === "required" && (
              <p className="error_p">Registration date is required</p>
            )}
          </div>

          <div className="form-group">
            <label for="">RTO Passing</label>
            <input
              defaultValue={TruckDetails?.rto}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
              type="text"
              className="form-control"
              id=""
              {...register("rto", {
                required: true,
                minLength: 2,
                maxLength: 2,
                pattern: /^[A-Za-z]+$/,
              })}
            />
            {errors?.rto?.type === "required" && (
              <p className="error_p">RTO code is required</p>
            )}
            {errors?.rto?.type === "minLength" && (
              <p className="error_p">RTO code cannot less then 2 characters</p>
            )}
            {errors?.rto?.type === "maxLength" && (
              <p className="error_p">RTO code cannot exceed 2 characters</p>
            )}
            {errors?.rto?.type === "pattern" && (
              <p className="error_p">
                RTO code must be in the form of Alphabte
              </p>
            )}
          </div>

          <div className="form-group">
            <label for="">FasTag Number</label>
            <input
              defaultValue={TruckDetails?.fastag_number}
              inputMode="numeric"
              className="form-control"
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
              id=""
              {...register("fastag_number", {
                required: true,
                pattern: /[0-9]/,
                minLength: 16,
                maxLength: 16,
              })}
            />
            {errors?.fastag_number?.type === "required" && (
              <p className="error_p">Fasttag Number is required</p>
            )}
            {errors?.fastag_number?.type === "pattern" && (
              <p className="error_p">Fasttag should be a number</p>
            )}
            {errors?.fastag_number?.type === "maxLength" && (
              <p className="error_p">Fasttag must be a 16 digit number</p>
            )}
            {errors?.fastag_number?.type === "minLength" && (
              <p className="error_p">Fasttag must be a number digit number</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Engine Number</label>
            <input
              defaultValue={TruckDetails?.engine_number}
              className="form-control"
              id=""
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
              {...register("engine_number", {
                required: true,
                pattern: /[0-9]/g,
              })}
            />
            {errors?.engine_number?.type === "required" && (
              <p className="error_p">Engine Number is required</p>
            )}
            {errors?.engine_number?.type === "pattern" && (
              <p className="error_p"> Engine_number should be a number</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Chassis Number</label>
            <input
              defaultValue={TruckDetails?.chasis_number}
              type="text"
              className="form-control"
              id=""
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
              {...register("chasis_number", {
                required: true,
                minLength: 17,
                validate: {
                  upper: (value) => /[A-Z]/.test(value),
                  number: (value) => /[0-9]/.test(value),
                },
              })}
            />
            {errors.chasis_number &&
              errors.chasis_number?.type === "required" && (
                <p className="error_p">Chassis Number is required</p>
              )}
            {errors.chasis_number && errors.chasis_number?.type === "upper" && (
              <p className="error_p">
                Chassis Number should contain Upper Case Alphabtes
              </p>
            )}
            {errors.chasis_number &&
              errors.chasis_number?.type === "number" && (
                <p className="error_p">Chassis Number should contain Numbers</p>
              )}
            {errors.chasis_number &&
              errors.chasis_number?.type === "minLength" && (
                <p className="error_p">
                  Chassis Number should contain atleast 17 characterS
                </p>
              )}
          </div>

          <div className="form-group">
            <label for="">Truck Length / Feet</label>
            <input
              defaultValue={TruckDetails?.truck_length}
              className="form-control"
              id=""
              onChangeCapture={(e) => handleCapture(e, "truck_length")}
              {...register("truck_length", {
                required: true,
                pattern: /[0-9]/g,
              })}
            />
            {errors?.truck_length?.type === "required" && (
              <p className="error_p">Truck Length is required</p>
            )}
            {errors?.truck_length?.type === "pattern" && (
              <p className="error_p">Truck Length must be a number</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Trolly Length / Feet</label>
            <input
              onChangeCapture={(e) => handleCapture(e, "trolley_length")}
              defaultValue={TruckDetails?.trolley_length}
              className="form-control"
              id=""
              {...register("trolley_length", {
                required: true,
                pattern: /[0-9]/g,
              })}
            />
            {errors?.trolley_length?.type === "required" && (
              <p className="error_p">Trolly Length is required</p>
            )}
            {errors?.trolley_length?.type === "pattern" && (
              <p className="error_p">Trolly Length must be a number</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Gross Weight / Tonne</label>
            <input
              defaultValue={TruckDetails?.gross_weight}
              onChangeCapture={(e) => handleCapture(e, "gross_weight")}
              className="form-control"
              id=""
              {...register("gross_weight", {
                required: true,
                pattern: /[0-9]/g,
              })}
            />
            {errors?.gross_weight?.type === "required" && (
              <p className="error_p">Gross Weight is required</p>
            )}
            {errors?.gross_weight?.type === "pattern" && (
              <p className="error_p">Gross Weight must be a number</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Unloading Weight</label>
            <input
              defaultValue={TruckDetails?.unloading_weight}
              className="form-control"
              id=""
              onChangeCapture={(e) => handleCapture(e, "unloading_weight")}
              {...register("unloading_weight", {
                required: true,
                pattern: /[0-9]/g,
              })}
            />
            {errors?.unloading_weight?.type === "required" && (
              <p className="error_p">Unloading weigth is required</p>
            )}
            {errors?.unloading_weight?.type === "pattern" && (
              <p className="error_p">Unloading weigth must be a number</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Pay load Weight</label>
            <input
              defaultValue={TruckDetails?.payloading_weight}
              className="form-control"
              onChangeCapture={(e) => handleCapture(e, "payloading_weight")}
              id=""
              {...register("payloading_weight", {
                required: true,
                pattern: /[0-9]/g,
              })}
            />
            {errors?.payloading_weight?.type === "required" && (
              <p className="error_p">Pay load weight is required</p>
            )}
            {errors?.payloading_weight?.type === "pattern" && (
              <p className="error_p">Pay load weight is required</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Purchase Date</label>
            <input
              defaultValue={TruckDetails?.purchased_date}
              type="Date"
              className="form-control"
              id=""
              onChangeCapture={(e) => handleCapture(e, "purchased_date")}
              {...register("purchased_date", {
                required: true,
              })}
            />
            {errors?.purchased_date?.type === "required" && (
              <p className="error_p">Purchase Date is required</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Invoice Date</label>
            <input
              defaultValue={TruckDetails?.invoice}
              type="Date"
              className="form-control"
              id=""
              {...register("invoice", {
                required: true,
              })}
            />
            {errors?.invoice?.type === "required" && (
              <p className="error_p">Invoice Date is required</p>
            )}
          </div>

          <div className="form-group">
            <label for="">Total Cost</label>
            <div className="d-flex">
              <div className="py-2 px-3 form_icon_icon">
                <i className="bi bi-currency-rupee"></i>
              </div>
              <input
                defaultValue={TruckDetails?.total_cost}
                onChangeCapture={(e) => handleCapture(e, "total_cost")}
                className="form-control_icon"
                id=""
                {...register("total_cost", {
                  required: true,
                  pattern: /[0-9]/g,
                })}
              />
            </div>
            {errors?.total_cost?.type === "required" && (
              <p className="error_p">Total cost is required</p>
            )}

            {errors?.total_cost?.type === "pattern" && (
              <p className="error_p">Total cost must be a number</p>
            )}
          </div>

          <div className="form-group">
            <label for="body_type">Body Type</label>
            <select
              defaultValue={TruckDetails?.body_type}
              className="form-control"
              id="bodytype"
              {...register("body_type", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              <option>Refrigerated</option>
              <option>trailer</option>
              <option>Open trailer</option>
            </select>

            {errors?.body_type?.type === "required" && (
              <p className="error_p">Body Type field is required</p>
            )}
          </div>
          <br />

          <button type="submit" className="mt-3 btn-next ">
            <h1>
              <i className="bi bi-arrow-right-square-fill"></i>
            </h1>
          </button>
          <div style={{ clear: "both" }}></div>
        </div>
      </form>
    </>
  );
}

export default TruckDetails;

import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LoadTruck from "../LoadTruck/LoadTruck";
import "../../common/Tabs/Tabs.css";
import AddExpense from "./AddExpense";
import ExpenseData from "./ExpenseData";

const Expense = () => {
  return (
    <>
      <div className="tabs-sec">
        <Tabs
          defaultActiveKey="addExpense"
          id="uncontrolled-tab-example"
          className="mb-4"
          justify
        >
          <Tab eventKey="addExpense" title="Add Expense">
            <AddExpense />
          </Tab>
          <Tab eventKey="expenses" title="View Expenses">
            <ExpenseData />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Expense;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Profile.css";
import SimpleBackdrop from "../../common/Spinner/Spinner";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

export default function Profile() {
  const userDetail = useSelector((state) => state?.loginDetail?.userDetail);
  const [loading, setLoading] = useState(false);
  if (userDetail == "") {
    setLoading(true);
  }

  return (
    <>
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <div>
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={8} lg={50} xs={12}>
                <div className="mb-3 mt-md-3 profile-box">
                  <div className="m-auto">
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        margin: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <Tooltip title="Edit" placement="right-end" arrow>
                            <SmallAvatar alt="Remy Sharp" src="/pngegg.png" />
                          </Tooltip>
                        }
                      >
                        <LargeAvatar alt="Travis Howard" src="/user.png" />
                      </Badge>
                    </Stack>
                  </div>
                  <hr />
                  <div className="justify-content-center align-items-center">
                    <Col>
                      <i className="bi bi-person-check red-text"></i>{" "}
                      <span className="p-2">
                        {" "}
                        Name &nbsp;:&nbsp; {userDetail.name}
                      </span>
                    </Col>

                    <hr />

                    <Col>
                      {" "}
                      <i className="bi bi-envelope-at red-text"></i>
                      <span className="p-2">
                        E-mail &nbsp;:&nbsp; {userDetail.email}
                      </span>{" "}
                    </Col>

                    <hr />

                    <Col>
                      <i className="bi bi-phone red-text"></i>
                      <span className="p-2">
                        Mobile &nbsp;:&nbsp; {userDetail.mobile}
                      </span>
                    </Col>

                    <hr />

                    <Col>
                      <i className="bi bi-lock red-text "></i>
                      <span className="p-2">
                        Need to change password ? {" "}
                        <Link to="/changepassword" className="red-text">
                          Change Password
                        </Link>
                      </span>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 50,
  height: 50,
  border: `5px solid ${theme.palette.background.paper}`,
  cursor: "pointer",
}));

const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: 130, // double the width
  height: 130, // double the height
}));

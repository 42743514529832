import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setDecrementStep, setDriverStates, } from '../../../../features/DriverForm';
import { BASE_URL } from '../../../../config';
import CustomizedSnackbars from '../../../common/Popup/Snackbar';
import CreatableSelect from 'react-select/creatable';
import Loader from '../../../common/Spinner/Loader';
function Bank() {
  const dispatch = useDispatch();
  const bearerToken = useSelector((state) => state?.bearerToken);
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const details = useSelector((state) => state?.driverFormReducer?.details);
  const contact = useSelector((state) => state?.driverFormReducer?.contact);
  const licenceDetails = useSelector((state) => state?.driverFormReducer?.licenceDetails);
  const references = useSelector((state) => state?.driverFormReducer?.references);
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    setLoading(true)
    const finalData = { ...details, ...contact, ...licenceDetails, ...references, ...data };

    const selectedAdhar = details?.driver_uploaded_aadhar[0]
    const selectedLicence = licenceDetails?.driver_uploaded_licence[0]


    const headers = {
      'Authorization': `Bearer ${bearerToken}`,
      'Content-Type': 'multipart/form-data'
    };

    const formData = new FormData();

    formData.append('operations',
      JSON.stringify({
        query: `mutation(
                      $firm_id:ID!,
                      $driver_type_:String!,
                      $driver_name_:String!,
                      $driver_father_name:String!,
                      $driver_dob:Date!,
                      $driver_join_date:Date!,
                      $driver_aadhaar:String!,
                      $driver_address:String!,
                  
                      $driver_phone_no:String!,
                      $driver_city:String!,
                      $driver_state:String!,
                      $driver_pin:String!,
                      $driver_blood_group:String!,
                      $driver_blacklisted:Boolean!,
                      $driver_block_reason:String!,
                      $driver_licence_no:String!,
                      $driver_class:String!,
                      $issue_date:Date!,
                      $valid_upto:Date!,
                      $authority:String!,
                      $r_name:String!,
                      
                      $r_relation:String!,
                      $r_address:String!,
                      $r_phone_no:String!,
                      $bank_name:String!,
                      $ac_no:String!,
                      $ifsc:String!,
                      $beneficiary:String!,
                      $driver_uploaded_aadhar:Upload!,
                      $driver_uploaded_licence:Upload!
                      ){
                        addDriver(

                          firm_id:$firm_id,
                          driver_type_:$driver_type_,
                          driver_name_:$driver_name_,
                          driver_father_name:$driver_father_name,
                          driver_dob:$driver_dob,
                          driver_join_date:$driver_join_date,
                          driver_aadhaar:$driver_aadhaar,
                          driver_address:$driver_address,

                          driver_phone_no:$driver_phone_no,
                          driver_city:$driver_city
                          driver_state:$driver_state,
                          driver_pin:$driver_pin,
                          driver_blood_group:$driver_blood_group,
                          driver_blacklisted:$driver_blacklisted,
                          driver_block_reason:$driver_block_reason,
                          driver_licence_no:$driver_licence_no,
                          driver_class:$driver_class,
                          issue_date:$issue_date,
                          valid_upto:$valid_upto,
                          authority:$authority,
                          r_name:$r_name,
                          r_relation:$r_relation,
                          r_address:$r_address,
                          r_phone_no:$r_phone_no,
                          bank_name:$bank_name,
                          ac_no:$ac_no,
                          ifsc:$ifsc,
                          beneficiary:$beneficiary,
                          driver_uploaded_aadhar:$driver_uploaded_aadhar,
                          driver_uploaded_licence:$driver_uploaded_licence
                        ){
                          message
                          error{
                            driver_type_,
                            driver_name_ ,
                            driver_father_name ,
                            driver_dob ,
                            driver_join_date,
                            driver_address,
                            driver_aadhaar
                            driver_phone_no,
                            driver_city,
                            driver_state,
                            driver_pin,
                            driver_blood_group,
                            driver_blacklisted,
                            driver_block_reason,
                            driver_licence_no,
                            driver_class,
                            issue_date,
                            valid_upto,
                            authority,
                            r_name,
                            r_relation,
                            r_address,
                            r_phone_no,
                            bank_name,
                            ac_no,
                            ifsc,
                            beneficiary
                          }
                          type
                        }
                      }`,
        variables: {
          firm_id: firm_id,
          driver_type_: finalData.driver_type_,
          driver_name_: finalData.driver_name_,
          driver_father_name: finalData.driver_father_name,
          driver_dob: finalData.driver_dob,
          driver_join_date: finalData.driver_join_date,
          driver_aadhaar: finalData.driver_aadhaar,
          driver_address: finalData.driver_address,
          driver_phone_no: finalData.driver_phone_no,
          driver_city: finalData.driver_city,
          driver_state: finalData.driver_state,
          driver_pin: finalData.driver_pin,
          driver_blood_group: finalData.driver_blood_group,
          driver_blacklisted: finalData.driver_blacklisted,
          driver_block_reason: finalData.driver_block_reason,
          driver_licence_no: finalData.driver_licence_no,
          driver_class: finalData.driver_class,
          issue_date: finalData.issue_date,
          valid_upto: finalData.valid_upto,
          authority: finalData.authority,
          r_name: finalData.r_name,
          r_relation: finalData.r_relation,
          r_address: finalData.r_address,
          r_phone_no: finalData.r_phone_no,
          bank_name: finalData.bank_name,
          ac_no: finalData.ac_no,
          ifsc: finalData.ifsc,
          beneficiary: finalData.beneficiary,
          driver_uploaded_aadhar: selectedAdhar,
          driver_uploaded_licence: selectedLicence

        }
      }))
    formData.append("0", selectedAdhar, selectedAdhar.name);
    formData.append("1", selectedLicence, selectedLicence.name);

    formData.append(
      "map",
      JSON.stringify({
        0: ["variables.driver_uploaded_aadhar"],
        1: ["variables.driver_uploaded_licence"]
      })
    );

    axios.post(BASE_URL, formData, { headers })
      .then(response => {
        if (response.data.data.addDriver.type == 'success') {
          setLoading(false)
          setMessage(response.data.data.addDriver.message);
          setType(response.data.data.addDriver.type);
          setShow(true);
          setTimeout(() => {
            dispatch(setDriverStates());
          }, 3000)
        }
        else {
          setLoading(false)
          setType(response.data.data.addDriver.type);
          setShow(true);
          if (response.data.data.addDriver.error?.ac_no) {
            setMessage(response.data.data.addDriver.error?.ac_no[0]);
          }
          else if (response.data.data.addDriver.error?.driver_pancard) {
            setMessage(response.data.data.addDriver.error?.driver_pancard[0]);
          }
          else if (response.data.data.addDriver.error?.driver_aadhaar) {
            setMessage(response.data.data.addDriver.error?.driver_aadhaar[0]);
          }
          else if (response.data.data.addDriver.error?.driver_email) {
            setMessage(response.data.data.addDriver.error?.driver_email[0]);
          }
          else if (response.data.data.addDriver.error?.driver_phone_no) {
            setMessage(response.data.data.addDriver.error?.driver_phone_no[0]);
          }
          else if (response.data.data.addDriver.error?.driver_licence_no) {
            setMessage(response.data.data.addDriver.error?.driver_licence_no[0]);
          }
        }
      })
      .catch(error => {

      });
  }
  const BackStep = () => {
    dispatch(setDecrementStep());
  }

  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
        setMessage();
      }, 5000);
    }
  }, [show]);
  const handleCapture = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setValue("ac_no", result);
  };

  const bank_list = [
    "State Bank of India (SBI)",
    "Punjab National Bank (PNB)",
    "Bank of Baroda (BOB)",
    "Canara Bank",
    "Union Bank of India",
    "HDFC Bank",
    "ICICI Bank",
    "Axis Bank",
    "Kotak Mahindra Bank",
    "IndusInd Bank",
    "Yes Bank",
    "IDBI Bank",
    "Bank of India (BOI)",
    "Central Bank of India",
    "Indian Bank",
    "Bank of Maharashtra",
    "Indian Overseas Bank",
    "Punjab & Sind Bank",
    "Federal Bank",
    "Karnataka Bank",
    "Karur Vysya Bank",
    "South Indian Bank",
    "Tamilnad Mercantile Bank Limited (TMB)",
    "Catholic Syrian Bank",
    "City Union Bank",
    "Dhanlaxmi Bank",
    "Jammu and Kashmir Bank",
    "Nainital Bank",
    "RBL Bank",
    "Yes Bank",
    "Allahabad Bank",
    "Andhra Bank",
    "Corporation Bank",
    "Oriental Bank of Commerce",
    "Syndicate Bank",
    "UCO Bank",
    "United Bank of India",
    "Citi Bank",
    "Deutsche Bank",
    "HSBC Bank",
    "Standard Chartered Bank"
  ]
  const bankData = bank_list.map(name => ({ value: name, label: name }));
  const capitalizeFirstLetter = (input) => {
    if (!input) return "";
    return input.split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    <div className='mt-5'>
      <Form className='adddriver-form p-3' onSubmit={handleSubmit(onSubmit)}>
        <div className='form-group w-50'>
          <label className="mb-2">Bank Name</label>
          <CreatableSelect
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "5px",
                paddingBottom: "2px",
                paddingTop: "2px",
              }),
            }}
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            onInputChange={capitalizeFirstLetter}
            onChange={(e) => setValue("bank_name", e?.value)}
            required
            options={bankData}
          />
        </div>
        <Form.Group className="form-group width_50" controlId="">
          <Form.Label>Bank A/C No.</Form.Label>
          <Form.Control
            onChangeCapture={handleCapture}
            type="text" {...register("ac_no", {
              required: true,
              pattern: /^[0-9]{9,18}$/,
            })} />
          {errors?.ac_no?.type === "required" && <p className="error_p">Bank account number is required</p>}
          {errors?.ac_no?.type === "pattern" && <p className="error_p">Bank account number is not valid</p>}
        </Form.Group>
        <Form.Group className="form-group width_50" controlId="">
          <Form.Label>IFSC Code</Form.Label>
          <Form.Control type="text" {...register("ifsc", {
            required: true,
            pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/
          })} />
          {errors?.ifsc?.type === "required" && <p className="error_p">IFSC code is required</p>}
          {errors?.ifsc?.type === "pattern" && <p className="error_p"> Please enter valid IFSC code</p>}
        </Form.Group>

        <Form.Group className="form-group width_50" controlId="">
          <Form.Label>
            Beneficiary Name</Form.Label>
          <Form.Control type="text" {...register("beneficiary", {
            required: true,
          })} />
          {errors?.beneficiary?.type === "required" && <p className="error_p">Beneficiary name is required</p>}
        </Form.Group>
        <div style={{ clear: "both" }}></div>
        <div className='mx-2'>

          <button type="submit" className='btn-submit' >
            {loading ? <><Loader /> {" "}
              Submitting...</> : <>Submit</>}
          </button>

          <button className='mt-3 btn-back ' onClick={BackStep} >
            <h1><i className="bi bi-arrow-left-square-fill"></i></h1>
          </button>

        </div>
        <div style={{ clear: "both" }}></div>
        {show && (
          <CustomizedSnackbars
            show={show}
            message={message}
            messageType={type}
          />
        )}

      </Form>
    </div>
  )
}

export default Bank;
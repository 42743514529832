 import React, { useState } from "react";
 import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";



 const Loader =() => {

return (
  <>
    <div><Spinner animation="border" size="sm" role="status">
      <span className="visually-hidden"></span>
    </Spinner></div>
  </>
  )

}
 export default Loader;
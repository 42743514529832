import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../config";
const TruckModal = (props) => {
  const [url, setUrl] = useState();
  const formData = props?.data;
  const modalData = formData?.find((el) => {
    return props?.truckId == el.truck_id;
  });

  useEffect(() => {}, [props]);

  const handleDocument = (event, item) => {
    event.preventDefault();

    const truckDocumentQuery = {
      query: `query($truck_id:ID,$type:String){
                truckDocuments(truck_id:$truck_id,type:$type){
                    document_path
                }
            }`,
      variables: {
        truck_id: event.target.value,
        type: item,
      },
    };

    axios
      .post(BASE_URL, truckDocumentQuery)
      .then((response) => {
        setUrl(response.data.data?.truckDocuments?.[0].document_path);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (url) {
      window.open(url, "_blank");
    }
    setTimeout(() => {
      setUrl();
    }, 2000);
  }, [url]);

  // const handleModalClose = () => {

  // }

  return (
    <>
      <Modal show={props?.modalHandler} size="xl" scrollable={true}>
        <Modal.Header
          className="mb-4"
          style={{
            backgroundColor: "#10233c",
            color: "white",
            height: "40px",
            textAlign: "center",
          }}
        >
          <h4 className=" my-0 m-auto">Truck Details</h4>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p style={{ color: "#F4801F" }} className="mx-3">
              <b> Basic Details </b>
            </p>

            <Form.Group className="form-group">
              <Form.Label>Truck Number</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.truck_number}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Model</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.model}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>ModelType</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.model_type}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Registration</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.new_registration}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Manufacturing</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.manufacturing_year
                  .split("-")
                  .reverse()
                  .join("-")}
                type="text"
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Valid Upto</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.valid_upto
                  .split("-")
                  .reverse()
                  .join("-")}
                type="text"
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Ownership</Form.Label>
              <div className="d-flex">
                <Form.Control
                  disabled
                  defaultValue={modalData?.ownership}
                  type="text"
                />
                <div className="py-0 px-0 form_icon">
                  {/* <button className="dark-btn h-100 bi bi-filetype-doc" value={modalData?.driver_id} onClick={handleAadharDownload} /> */}
                </div>
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Registration Date</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.registration_date
                  .split("-")
                  .reverse()
                  .join("-")}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Fastag Number</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.fastag_number}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>RTO</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.rto}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Engine Number</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.engine_number}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Chasis Number</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.chasis_number}
                type="text"
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Truck Length</Form.Label>
              <div className="d-flex">
                <Form.Control
                  disabled
                  defaultValue={modalData?.truck_length}
                  type="text"
                />
                <div className="py-0 px-0 form_icon"></div>
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Trolley Length</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.trolley_length}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Gross Weight</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.gross_weight}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Unloading Weight</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.unloading_weight}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Payloading Weight</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.payloading_weight}
                type="text"
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Purchased Date</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.purchased_date
                  .split("-")
                  .reverse()
                  .join("-")}
                type="text"
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Invoice</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.invoice}
                type="text"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Total Cost</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.total_cost}
                type="text"
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Body Type</Form.Label>
              <Form.Control
                disabled
                defaultValue={modalData?.body_type}
                type="text"
              />
            </Form.Group>

            <div style={{ clear: "both" }}></div>
            <hr />

            <p style={{ color: "#F4801F" }} className="mx-3">
              <b> Truck Documents </b>
            </p>

            <ul>
              <li>
                <b className="me-4">REGISTARTION CARD</b> -{" "}
                <button
                  className="btn btn-success bi bi-eye ms-5"
                  value={modalData?.truck_id}
                  onClick={(event) =>
                    handleDocument(event, "registration_card")
                  }
                ></button>
              </li>
              <br />
              <li>
                <b className="me-5">PUC CERTIFICATE</b> -{" "}
                <button
                  className="btn btn-success ms-5 bi bi-eye"
                  value={modalData?.truck_id}
                  onClick={(event) => handleDocument(event, "puc_certificate")}
                ></button>
              </li>
              <br />
              <li>
                <b className="me-5 pe-4">INSURANCE</b> &nbsp;&nbsp;&nbsp;&nbsp;-
                &nbsp;
                <button
                  className="btn btn-success ms-5 bi bi-eye ms-5"
                  value={modalData?.truck_id}
                  onClick={(event) => handleDocument(event, "insurance")}
                ></button>
              </li>
              <br />
              <li>
                <b className="me-5 pe-5">PERMIT</b>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- &nbsp;
                <button
                  className="btn btn-success ms-5  bi bi-eye"
                  value={modalData?.truck_id}
                  onClick={(event) => handleDocument(event, "permit")}
                ></button>
              </li>
            </ul>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => props?.modalAction(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TruckModal;

import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import '../Verification/Verification.css'
import '../../common/Form/Form.css';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { instance } from "../../../axioConfig";
import { useDispatch } from "react-redux";
import { setLogin, setTempToken } from "../../../features/Login";
import { useForm } from "react-hook-form";
import Login from "../Login/Login";
import { Navigate, useNavigate } from "react-router";
import ToastBox from "../../common/Toast/ToastBox";

function ForgotPassword() {
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "all"
  });
  const [registration, setRegistration] = useState(false);
  const [login, setLogin] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  const [responseMessage, setResponseMessage] = useState(null)
  const [responseError, setResponseError] = useState(null)
  const [responseType, setResponseType] = useState(null)
  const [count, setCount] = useState(null);
  const [disable, setDisable] = useState(false)
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setDisable(true)
    setResponseMessage(null)
    setResponseError(null)
    const query = {
      query: `mutation($email:String!){
        resetPasswordLink(email:$email){
          error{
            email
            password
            message
          }
          message
          type
        }
      }`,
      variables: {
        "email": data.email
      }
    }
    instance.post('', query
    ).then(function (response) {
      if (response.status == 200 && response.data?.data.resetPasswordLink.type == "success") {
        setResponseMessage(response.data?.data.resetPasswordLink.message)
        setResponseType(response.data?.data.resetPasswordLink.type)
        setCount(10);
        setShow(true)
        setResponseError(null)
      }
      else {
        setResponseType()
        setShow(true)
        setResponseType(response.data?.data.resetPasswordLink.error.type)
        setResponseError(response.data?.data.resetPasswordLink.error.message)
        setDisable(false)
      }
    })
      .catch(function (error) {

      });
  }
  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
      }, 5000);
    }
  }, [show]);


  // when count is 0, navigate

  useEffect(() => {
    if (count != null) {
      const interval = setInterval(() => {
        // update the state after 1000ms
        setCount((currentCount) => currentCount - 1);
      }, 1000);
      count === 0 && navigate("/");
      // clean up the interval
      return () => clearInterval(interval);
    }
  }, [count, navigate]);
  if (login == true) {
    return <Navigate to="/" />
  }
  if (registration == true) {
    return <Navigate to="/register" />;
  }
  return (
    <div className="container-fluid otp-verification fgt-password ">
      <div className="row">
        <div className="col-lg-5 left d-flex justify-content-center align-items-center">
          <Link to="/">
            <Navbar.Brand>
              <img src="/logo.png" className="img-fluid site-logo" />
            </Navbar.Brand>
          </Link>
        </div>

        <div className="col-lg-7 mt-5 mt-lg-0 right d-flex justify-content-center align-items-center">
          <i className="bi bi-lock-fill"></i>
          <h2 className="mb-4 red-text">Forgot Password?</h2>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="text-center mb-3">Enter your email address</h4>
            <Form.Group className="form-group width_100" controlId="">
              <Form.Control type="text" placeholder="Email address" className={errors?.email ? "border border-danger p-2" : null}  {...register("email", { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i })} />
              {errors.email && errors.email.type === "required" && <span className="error_p mt-1">Please Enter email</span>}
              {errors.email && errors.email.type === "pattern" && <span className="error_p">Please enter a valid email</span>}
            </Form.Group>
            <div className="mx-3">
              {show && (
                <ToastBox
                  toggle={show}
                  message={responseMessage || responseError}
                  messageType={responseType}
                />
              )}
            </div>
            {count ? (
              <div
                className=" alert alert-primary d-flex justify-content-center m-3"
                role="alert"
              >
                Redirevcting you in {count} sec

              </div>
            ) : null}
            <button
              disabled={disable}
              type="submit"
              className="d-block mx-auto btn dark-btn btn-lg mb-3"
              id="verify-otp">
              Continue
            </button>
            <div className="verfi-code">
              <p className="d-flex justify-content-center">Alreday have an account? <Button variant="primary"
                className="dark-btn mt-3"
                onClick={() => {
                  setLogin(true)
                }} >
                Login
              </Button></p>
              <p className="d-flex justify-content-center">New here? <Button variant="primary"
                className="dark-btn"
                onClick={() => {
                  setRegistration(true);
                }} >
                Register
              </Button></p>
            </div>

          </Form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "axios";
import "./Expense.css";
import moment from "moment";
import { BASE_URL } from "../../../config";
import CustomizedSnackbars from "../../common/Popup/Snackbar";
import Loader from "../Loader/Loader";

const AddExpense = () => {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const [truckData, setTruckData] = useState([]);
  const [loading, setLoading] = useState(false);
  const TodayDate = moment().format("YYYY-MM-DD");
  const [messageType, setMessageType] = useState("");
  const [mainError, setMainError] = useState([]);
  const [show, setShow] = useState(false);
  const [expenseType, setExpenseType] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false)
  const headers = {
    Authorization: `Bearer ${bearerToken} `,
    "Content-Type": "application/json",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    // =========== Query to get All Truck Numbers ============
    const query = {
      query: `query($firm_id: ID!){
    truck(firm_id: $firm_id){
        truck_number
        truck_id
      
    }
  } `,
      variables: {
        firm_id: firm_id,
      },
    };
    // ====== Query to get Truck No. whose Trip Status is Active ========
    const query2 = {
      query: `query($firm_id:ID!,$trip_status:String){
        firmTrip(firm_id:$firm_id,trip_status:$trip_status) {
          truckTrip {
            trip_id
            truck {
              truck_number
            }
          }
        }
  } `,
      variables: {
        trip_status: "Active",
        firm_id: firm_id,
      },
    };

    {
      expenseType == "trip_expense"
        ? axios
          .post(BASE_URL, query2, { headers })
          .then((response) => {
            setTruckData(response.data?.data.firmTrip?.truckTrip);
            setLoading(false);
          })
          .catch((error) => { })
        : axios
          .post(BASE_URL, query, { headers })
          .then((response) => {
            setTruckData(response.data?.data.truck);
            setLoading(false);
          })
          .catch((error) => { });
    }
  }, [expenseType, firm_id]);

  useEffect(() => {
    reset();
  }, [firm_id]);

  function onSubmit(data) {
    setLoadingBtn(true)
    const query = {
      query: `mutation($truck_id:ID!,$expense_date:Date!,$expense_type:String!,$expense_area:String!,$expense_amount:Int!){
    addExpense(truck_id: $truck_id, expense_date:$expense_date, expense_type: $expense_type, expense_area:$expense_area, expense_amount: $expense_amount){
       message
       type
       error{
          truck_id
          expense_date
          expense_type
          expense_area
          expense_amount
      }
    }
  } `,
      variables: {
        truck_id: data.truck_id,
        expense_date: data.expense_date,
        expense_type: data.expense_type,
        expense_area: data.expense_area,
        expense_amount: data.expense_amount,
      },
    };

    const query2 = {
      query: `mutation($trip_id:ID!,$trip_expense_date:Date!,$trip_expense_area:String!,$trip_expense_amount:Int!){
        addTripExpense(trip_id:$trip_id,
                       trip_expense_date:$trip_expense_date,
                       trip_expense_area:$trip_expense_area,
                       trip_expense_amount:$trip_expense_amount){
          type
          message
        error{
          trip_id
          trip_expense_date
          trip_expense_area
          trip_expense_amount
        }
        }
      }`,
      variables: {
        trip_id: data.trip_id,
        trip_expense_date: data.trip_expense_date,
        trip_expense_type: data.trip_expense_type,
        trip_expense_area: data.trip_expense_area,
        trip_expense_amount: data.trip_expense_amount,
      },
    };

    {
      expenseType == "trip_expense"
        ? axios
          .post(BASE_URL, query2, { headers })
          .then((response) => {
            if (response.data.data.addTripExpense.type == "success") {
              setLoadingBtn(false)
              setMessageType(response.data?.data.addTripExpense.type);
              setShow(true);
              setMainError(response.data?.data.addTripExpense.message);
              reset();
            }
          })
          .catch((error) => { })
        : axios
          .post(BASE_URL, query, { headers })
          .then((response) => {
            if (response.data.data.addExpense.type == "success") {
              setLoadingBtn(false)
              setMessageType(response.data?.data.addExpense.type);
              setShow(true);
              setMainError(response.data?.data.addExpense.message);
              reset();
            }
          })
          .catch((error) => { });
    }
  }

  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  useEffect(() => { }, [expenseType]);

  return (
    <>
      <Form
        className="expense-form addtruck-form mt-5 p-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d-lg-flex d-md-flex justify-content-between mb-5 mt-0">
          <Form.Group
            className="form-width form-group"
            style={{ width: "33.33%" }}
          >
            <Form.Label>Expense Type</Form.Label>
            <Form.Select
              onChangeCapture={(e) => setExpenseType(e.target.value)}
              {...register("expense_type", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              <option value="maintenance_expense">Maintenance Expense</option>
              <option value="documentation_expense">
                Documentation Expense
              </option>
              <option value="trip_expense">Trip Expense</option>
            </Form.Select>
            {errors?.expense_type?.type === "required" && (
              <p className="error_p">Please Select Expense Type</p>
            )}
          </Form.Group>

          {expenseType == "trip_expense" ? (
            <Form.Group
              className="form-width form-group"
              style={{ width: "33.33%" }}
            >
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                max={TodayDate}
                {...register("trip_expense_date", {
                  required: true,
                })}
              />
              {errors?.trip_expense_date?.type === "required" && (
                <p className="error_p">Please Select a Date</p>
              )}
            </Form.Group>
          ) : (
            <Form.Group
              className="form-width form-group"
              style={{ width: "33.33%" }}
            >
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                max={TodayDate}
                {...register("expense_date", {
                  required: true,
                })}
              />
              {errors?.expense_date?.type === "required" && (
                <p className="error_p">Please Select a Date</p>
              )}
            </Form.Group>
          )}
        </div>
        <hr />
        {expenseType == "trip_expense" ? (
          <Form.Group className="form-group ">
            <Form.Label>Select Truck</Form.Label>
            <Form.Select
              {...register("trip_id", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              {loading && <option value="">...Loading</option>}

              {truckData?.map((t) => (
                <option value={t.trip_id}>{t.truck?.truck_number}</option>
              ))}
            </Form.Select>
            {errors?.trip_id?.type === "required" && (
              <p className="error_p">Please Select Truck</p>
            )}
          </Form.Group>
        ) : (
          <Form.Group className="form-group ">
            <Form.Label>Select Truck</Form.Label>
            <Form.Select
              {...register("truck_id", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              {loading && <option value="">...Loading</option>}
              {truckData?.map((t) => (
                <option value={t.truck_id}>{t.truck_number}</option>
              ))}
            </Form.Select>
            {errors?.truck_id?.type === "required" && (
              <p className="error_p">Please Select Truck</p>
            )}
          </Form.Group>
        )}

        {expenseType == "trip_expense" ? (
          <>
            {" "}
            <Form.Group className="form-group ">
              <Form.Label>Expense Area</Form.Label>

              <Form.Select
                {...register("trip_expense_area", {
                  required: true,
                })}
              >
                {expenseType == "" && <option>Select Expense Type</option>}

                <>
                  <option value="">-Select-</option>
                  <option value="driver_expense">Driver Expense</option>
                  <option value="fuel_expense">Fuel Expense</option>
                  <option value="fastag_toll">FasTag / Toll</option>
                  <option value="miscellaneous">Miscellaneous</option>
                </>
              </Form.Select>

              {errors?.trip_expense_area?.type === "required" && (
                <p className="error_p">Please Select Expense Area</p>
              )}
            </Form.Group>
            <Form.Group className="form-group ">
              <Form.Label>Expense Amount</Form.Label>
              <div className="d-flex">
                <div className="py-2 px-3 form_icon_icon">
                  <i className="bi bi-currency-rupee"></i>
                </div>
                <Form.Control
                  className="form-control_icon"
                  type="number"
                  {...register("trip_expense_amount", {
                    required: true,
                    valueAsNumber: true,
                    min: 1,
                  })}
                />
              </div>
              {errors?.trip_expense_amount?.type === "required" && (
                <p className="error_p">Please Enter Expense Amount</p>
              )}
              {errors?.trip_expense_amount?.type === "min" && (
                <p className="error_p">
                  Expense Amount can not be Negative or zero
                </p>
              )}
            </Form.Group>
          </>
        ) : (
          <>
            {" "}
            <Form.Group className="form-group ">
              <Form.Label>Expense Area</Form.Label>

              <Form.Select
                {...register("expense_area", {
                  required: true,
                })}
              >
                {expenseType == "" && <option>Select Expense Type</option>}
                {expenseType == "documentation_expense" && (
                  <>
                    <option value="">-Select-</option>
                    <option value="truck_insurance">Truck Insurance</option>
                    <option value="puc_certificate">PUC Certificate</option>
                    <option value="permit">Permit</option>
                    <option value="road_tax">Road Tax</option>
                    <option value="miscellaneous">Miscellaneous</option>
                  </>
                )}
                {/* {loading && <option value="">...Loading</option>} */}

                {expenseType == "maintenance_expense" && (
                  <>
                    <option value="">-Select-</option>
                    <option value="tyres">Tyres</option>
                    <option value="body_parts">Body/Spare parts</option>
                    <option value="general_service">General Service</option>
                    <option value="major_service">Major Service</option>
                    <option value="miscellaneous">Miscellaneous</option>
                  </>
                )}
              </Form.Select>

              {errors?.expense_area?.type === "required" && (
                <p className="error_p">Please Select Expense Area</p>
              )}
            </Form.Group>
            <Form.Group className="form-group ">
              <Form.Label>Expense Amount</Form.Label>
              <div className="d-flex">
                <div className="py-2 px-3 form_icon_icon">
                  <i className="bi bi-currency-rupee"></i>
                </div>
                <Form.Control
                  type="number"
                  style={{
                    borderRadius: "0px",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                  {...register("expense_amount", {
                    required: true,
                    valueAsNumber: true,
                    min: 1,
                  })}
                />
              </div>
              {errors?.expense_amount?.type === "required" && (
                <p className="error_p">Please Enter Expense Amount</p>
              )}
              {errors?.expense_amount?.type === "min" && (
                <p className="error_p">
                  Expense Amount can not be Negative or zero
                </p>
              )}
            </Form.Group>
          </>
        )}

        <div style={{ clear: "both" }}></div>
        <div>
          <button className="btn dark-btn m-auto " type="submit">
            {loadingBtn ? <div className="d-flex"><Loader /><span>{" "}Submitting...</span></div> : <>Submit</>}
          </button>
        </div>
        {show && (
          <CustomizedSnackbars
            show={show}
            message={mainError}
            messageType={messageType}
          />
        )}
      </Form>
    </>
  );
};

export default AddExpense;
